<template>
  <a-spin :spinning="loading" :tip="loadingTip">
    <div class="tableList">
      <div class="search">
        <div class="search-body">
          <a-form-model layout="inline">
            <a-form-model-item label="部室">
              <a-cascader
                :options="orgTree"
                v-model="search.orgId"
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
                placeholder="请选择部室"
              />
            </a-form-model-item>
            <a-form-model-item label="标题">
              <a-input v-model="search.title" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item label="状态">
              <a-select
                v-model="search.status"
                placeholder="请选择"
                allowClear
                style="width: 120px"
              >
                <a-select-option :value="0">待提交</a-select-option>
                <a-select-option :value="1">已提交</a-select-option>
                <a-select-option :value="2">通过</a-select-option>
                <a-select-option :value="3">不通过</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="日期">
              <a-range-picker @change="onTimeChange" />
            </a-form-model-item>
            <a-form-model-item>
              <p-button type="primary" name="搜索" @click="searchList" />
            </a-form-model-item>
            <a-form-model-item style="float: right">
              <a-button
                type="primary"
                style="margin-right: 10px"
                @click="addOrUpdate(null)"
                >新增</a-button
              >
              <a-button
                type="default"
                style="margin-right: 10px"
                @click="exportData"
                >导出</a-button
              >
              <ExportFileDownloadBtn
                :fileList="excelFiles"
                @listFiles="listExcelFiles"
                @downloadFile="downloadFile"
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
      <div class="tableItem">
        <a-table
          bordered
          rowKey="id"
          :columns="tableInfo.columns"
          :data-source="tableInfo.datas"
          :pagination="tableInfo.pagination"
          @change="onChange"
          :locale="{ emptyText: '暂无数据' }"
          :scroll="{ y: tableInfo.tableHeight }"
        >
          <template slot="status" slot-scope="info">
            <processStatus :processModel="info" />
          </template>
          <template slot="action" slot-scope="action">
            <template v-if="action.creator == currUser">
              <a-button
                v-if="action.status == 0 || action.status == 3"
                @click="addOrUpdate(action)"
                size="small"
                type="primary"
                >修改</a-button
              >
              <a-popconfirm
                title="提交后数据不可更改，是否确认？"
                @confirm="changeStatus(action.id, 1)"
                v-if="action.status == 0 || action.status == 3"
              >
                <a-button size="small" type="danger">提交</a-button>
              </a-popconfirm>
              <a-popconfirm
                v-if="action.status == 0 || action.status == 3"
                title="确定要删除吗？"
                @confirm="deleteItem(action.id)"
              >
                <a-button size="small" type="danger">删除</a-button>
              </a-popconfirm>
            </template>
            <a-button
              @click="addOrUpdate(action)"
              size="small"
              type="primary"
              v-if="
                action.processExecuteStatus && action.taskKey != '其他-验收'
              "
              >审批</a-button
            >
            <a-button
              @click="addOrUpdate(action)"
              size="small"
              type="primary"
              v-if="
                action.processExecuteStatus && action.taskKey == '其他-验收'
              "
              >验收</a-button
            >
            <a-button
              @click="addOrUpdate(action, true)"
              size="small"
              type="default"
              v-if="action.status == 1 || action.status == 2"
              >详情</a-button
            >
          </template>
        </a-table>
      </div>
      <a-modal
        :title="editor.title"
        v-model="editor.visible"
        width="90%"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <createdocumentprint :info="editor.info" @callback="modelCallBack" />
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
import documentprintApi from "@/api/work/documentprint";
import createdocumentprint from "./createdocumentprint.vue";
import ExportFileDownloadBtn from "../components/ExportFileDownloadBtn.vue";
import orgApi from "@/api/org";
import InnerTableForRowSpan from "../components/InnerTableForRowSpan.vue";
export default {
  name: "documentprint",
  data() {
    return {
      loading: false,
      loadingTip: "加载中",
      search: {
        itemName: "",
        orgId: [],
        status: null,
      },
      tableInfo: {
        datas: [],
        columns: [
          {
            key: "index",
            align: "center",
            title: "序号",
            width: 70,
            customRender: (text, record, index) => {
              return (
                (this.tableInfo.pagination.current - 1) *
                  this.tableInfo.pagination.pageSize +
                index +
                1
              );
            },
          },
          {
            dataIndex: "orgName",
            align: "center",
            title: "部室",
          },
          {
            dataIndex: "time",
            align: "center",
            title: "日期",
            width: 110,
          },
          {
            dataIndex: "handlerName",
            align: "center",
            title: "经办人",
            width: 80,
          },
          {
            dataIndex: "title",
            align: "center",
            title: "文印标题",
            customRender: (text, record, index) => {
              return {
                attrs: {
                  style: "padding: 0",
                },
                children: (
                  <InnerTableForRowSpan
                    records={record.items}
                    columnName="title"
                  />
                ),
              };
            },
          },
          {
            dataIndex: "count",
            align: "center",
            title: "数量",
            width: 80,
            customRender: (text, record, index) => {
              return {
                attrs: {
                  style: "padding: 0",
                },
                children: (
                  <InnerTableForRowSpan
                    records={record.items}
                    columnName="count"
                  />
                ),
              };
            },
          },
          {
            key: "status",
            align: "center",
            title: "状态",
            width: 180,
            scopedSlots: { customRender: "status" },
          },
          {
            title: "操作",
            key: "action",
            align: "center",
            width: 200,
            scopedSlots: { customRender: "action" },
          },
        ],
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: false,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `总共${total}条`,
        },
      },
      selectRows: [],
      editor: {
        title: "编辑",
        visible: false,
        info: {},
      },

      orgTree: [],
      excelFiles: [],
      currUser: localStorage.getItem(this.$commons.User.userName),
    };
  },
  components: {
    createdocumentprint,
    ExportFileDownloadBtn,
    InnerTableForRowSpan,
  },
  mounted() {
    this.getOrgTree();
    this.getPageList();
  },
  methods: {
    getOrgTree() {
      orgApi.getTree(true, false).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgTree = res.data;
        }
      });
    },
    deleteItem(id) {
      this.loading = true;
      documentprintApi.deleteItem(id).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    changeStatus(id, status) {
      this.loading = true;
      documentprintApi.changeStatus(id, status).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    exportData() {
      var _this = this;
      _this.loading = true;
      documentprintApi.exportData({ ...this.search }).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success(res.errorMsg);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    downloadFile(url, fileName) {
      var _this = this;
      _this.loading = true;
      documentprintApi.downloadFile(url, fileName, (d) => {
        _this.loadingTip = "已完成" + d + "%";
        if (d == 100) {
          setTimeout(function () {
            _this.loading = false;
          }, 2000);
        }
      });
    },
    async getPageList() {
      let _this = this;
      let param = {
        ...this.search,
        pageNow: _this.tableInfo.pagination.current,
        pageSize: _this.tableInfo.pagination.pageSize,
      };
      param.orgId = param.orgId ? param.orgId[param.orgId.length - 1] : null;
      documentprintApi.getList(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.tableInfo.datas = res.data.records;
          _this.tableInfo.pagination.total = Number(res.data.total);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    onTimeChange(date, dateString) {
      this.search.acceptStartDate = dateString[0];
      this.search.acceptEndDate = dateString[1];
    },
    onChange(page) {
      let _this = this;
      _this.tableInfo.pagination.current = page.current;
      _this.tableInfo.pagination.pageSize = page.pageSize;
      _this.getPageList();
    },
    searchList() {
      this.getPageList();
    },

    addOrUpdate(info) {
      let _this = this;
      _this.editor.visible = true;
      if (info) {
        _this.editor.title = "编辑";
        if (info.status == 1 || info.status == 2) {
          _this.editor.title = "查看";
        }
      } else {
        _this.editor.title = "新增";
      }
      _this.editor.info = info;
    },
    showList(info) {
      //打开弹出框
      let _this = this;
      _this.itemListInfo.visible = true;
      _this.itemListInfo.title = "字典子集";
      _this.itemListInfo.info = info;
    },
    modelCallBack() {
      this.editor.visible = false;
      this.getPageList();
    },
    listExcelFiles() {
      documentprintApi.getExcelList().then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          this.excelFiles = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less">
</style>
