<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <div class="search" v-if="modal == false">
        <div class="search-body" style="height: 50px">
          <a-button style="float: right" @click="gotoList" type="default"
            >公用经费预算列表</a-button
          >
        </div>
      </div>

      <div
        :style="{
          padding: '10px',
          width: info == null ? '80%' : '100%',
          margin: '0px auto',
        }"
      >
        <a-form-model
          ref="publicBudgetForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <div
            style="
              width: 100%;
              font-size: 40px;
              text-align: center;
              padding-bottom: 20px;
            "
          >
            河北省教育厅直属事业单位经费支出预算表
          </div>
          <a-form-model-item
            :labelCol="{ span: 6 }"
            :wrapper-col="{ span: 16 }"
            label="编报日期"
            prop="reportTime"
          >
            <a-date-picker v-model="form.reportTime" />
          </a-form-model-item>
          <table style="width: 100%" class="formtable">
            <tr>
              <td>
                <span style="color: red">*</span>
                编报部门
              </td>
              <td colspan="3" style="text-align: left">
                <a-form-model-item
                  label=""
                  prop="org"
                  style="margin-bottom: 0px"
                >
                  <a-cascader
                    :options="orgs"
                    style="width: 300px"
                    v-model="form.orgId"
                    @change="orgChanged"
                    :fieldNames="{
                      label: 'name',
                      value: 'id',
                      children: 'children',
                    }"
                    placeholder="请选择部室"
                  />
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td>
                <span style="color: red">*</span>
                支出事项
              </td>
              <td style="text-align: left">
                <a-form-model-item
                  label=""
                  prop="shixiang"
                  style="margin-bottom: 0px"
                >
                  <a-input v-model="form.shixiang" />
                </a-form-model-item>
              </td>
              <td>
                <span style="color: red">*</span>
                预算金额
              </td>
              <td style="text-align: left">
                <a-form-model-item
                  label=""
                  prop="amount"
                  style="margin-bottom: 0px"
                >
                  <a-input-number v-model="form.amount" style="width: 60%" />
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td>
                <span style="color: red">*</span>
                预算明细
              </td>
              <td colspan="3" style="text-align: left">
                <a-form-model-item
                  label=""
                  prop="detail"
                  style="margin-bottom: 0px"
                >
                  <a-textarea
                    v-model="form.detail"
                    :auto-size="{ minRows: 16 }"
                    style="height: 100px; width: 100%"
                  />
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td>附件</td>
              <td colspan="4" style="text-align: left">
                <a-upload
                  :action="uploadFileUrl"
                  :fileList="fileList"
                  @preview="showWpsRead"
                  @change="handleFileChange"
                  :remove="() => upLoadRemove"
                  style="margin-bottom: 0px"
                >
                  <div v-if="this.form.status == 0 || this.form.status == 3">
                    <a-button><a-icon type="upload" />上传文件</a-button>
                  </div>
                </a-upload>
              </td>
            </tr>
            <tr>
              <td>
                <span style="color: red">*</span>
                预算支出项目
              </td>
              <td style="text-align: left" colspan="3">
                <a-form-model-item
                  label=""
                  prop="xiangmu"
                  style="margin-bottom: 0px"
                >
                  <a-input v-model="form.xiangmu" />
                </a-form-model-item>
              </td>
            </tr>
            <tr>
              <td>编报人</td>
              <td style="text-align: left">
                <a-form-model-item
                  label=""
                  prop="postUser"
                  style="margin-bottom: 0px"
                  v-if="form.status == 0 || form.status == 3"
                >
                  <a-input readOnly v-model="form.postUserName" />
                </a-form-model-item>
                <div style="text-align: right" v-else>
                  <img
                    style="width: 120px"
                    :src="form.postUserPic"
                    v-if="form.postUserPic"
                  />
                  <span v-else>{{ form.postUserName }} </span>
                  <div>{{ form.reportTime.format("YYYY年MM月DD日") }}</div>
                </div>
              </td>
              <td>财务意见</td>
              <td>
                <auditPrint
                  :infoData="form"
                  taskPrintKey="CaiWu"
                  @callback="auditCb"
                />
              </td>
            </tr>
            <tr>
              <td>办公室财务副主任意见</td>
              <td>
                <auditPrint
                  :infoData="form"
                  taskPrintKey="BanGongShiFuZhuRen"
                  @callback="auditCb"
                />
              </td>
              <td>办公室主任意见</td>
              <td>
                <auditPrint
                  :infoData="form"
                  taskPrintKey="BanGongShiZhuRen"
                  @callback="auditCb"
                />
              </td>
            </tr>
            <tr>
              <td>分管财务院领导意见</td>
              <td>
                <auditPrint
                  :infoData="form"
                  taskPrintKey="BanGongShiFenGuanLingDao"
                  @callback="auditCb"
                />
              </td>
              <td>单位负责人意见</td>
              <td>
                <auditPrint
                  :infoData="form"
                  taskPrintKey="YuanChang"
                  @callback="auditCb"
                />
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <a-row>
                  <a-col :span="9" style="text-align: left">
                    备注：预借支票代码：
                  </a-col>
                  <a-col :span="8" style="text-align: left"> 日期： </a-col>
                  <a-col :span="7" style="text-align: left"> 借用人： </a-col>
                </a-row>
              </td>
            </tr>
          </table>
          <a-row style="text-align: center; margin-top: 20px">
            <a-col>
              <a-button
                type="primary"
                v-if="form.status == 0 || form.status == 3"
                style="margin-right: 10px"
                @click="handleSubmit(0)"
                >保存</a-button
              >
              <a-popconfirm
                v-if="form.status == 0 || form.status == 3"
                title="提交后数据不可更改，是否确认？"
                @confirm="handleSubmit(1)"
              >
                <a-button type="danger" style="margin-right: 10px"
                  >提交</a-button
                >
              </a-popconfirm>
              <a-button
                type="primary"
                v-if="form.processExecuteStatus"
                style="margin-right: 10px"
                @click="showaudit()"
                >审批</a-button
              >
              <a-button
                v-if="form.status == 2"
                @click="showWps"
                style="margin-right: 10px"
                >打印</a-button
              >
              <a-button v-if="modal" @click="cancelClick">取消</a-button>
            </a-col>
          </a-row>
        </a-form-model>
        <a-modal
          title="选择用户"
          v-model="selectuseModal.visible"
          width="70%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <selectuser @callback="selectuserCallback" />
        </a-modal>
        <a-modal
          title="审批"
          v-model="auditModal.visible"
          width="40%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <audit
            :info="auditModal.info"
            :formValidPass="formValidPass"
            @callback="auditCallBack"
          />
        </a-modal>
        <a-modal
          title="票据整理单"
          v-model="expenditureModal.visible"
          width="60%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <expenditure
            :info="expenditureModal.info"
            @callback="expenditureModalCallBack"
          />
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>
<script>
import publicbudgetApi from "@/api/work/publicbudget";
import orgApi from "@/api/org";
import selectuser from "../components/searchuser.vue";
import expenditure from "./expenditure.vue";
import pinyin from "js-pinyin";
import request from "@/request";
export default {
  name: "publicbudget",
  data() {
    return {
      form: {
        status: 0,
        expenditure: null,
      },
      labelCol: { span: 1 },
      wrapperCol: { span: 23 },
      orgs: [],
      fileList: [],
      uploadFileUrl: "",
      upLoadRemove: false,
      loading: false,
      selectuseModal: {
        visible: false,
        record: null,
      },
      expenditureModal: {
        visible: false,
        info: null,
      },
      auditModal: {
        info: null,
        visible: false,
      },
      rules: {
        reportTime: [
          {
            required: true,
            message: "请选择编报时间",
            trigger: "change",
          },
        ],
        org: [
          {
            required: true,
            message: "请选择编报部门",
            trigger: "change",
            validator: (rule, value, callback) => {
              if (this.form.orgId.length == 0) {
                callback(new Error(""));
                return;
              }
              callback();
            },
          },
        ],
        shixiang: [
          {
            required: true,
            message: "请填写支出事项",
            trigger: "blur",
          },
        ],
        amount: [
          {
            required: true,
            message: "请填写预算金额",
            trigger: "blur",
          },
        ],
        detail: [
          {
            required: true,
            message: "请填写预算明细",
            trigger: "blur",
          },
        ],
        xiangmu: [
          {
            required: true,
            message: "请填写预算支出项目",
            trigger: "blur",
          },
        ],
      },
      formValidPass: true,
      currUser: localStorage.getItem(this.$commons.User.userName),
    };
  },
  props: {
    info: {
      type: Object,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  components: { selectuser, expenditure },
  mounted() {
    this.getOrgTree();
    this.uploadFileUrl = request.getUploadUrl("dispatch");
    if (this.info && this.info.id > 0) {
      this.getData();
    } else {
      this.form.reportTime = this.form.time = this.$moment();
      this.upLoadRemove = true;
    }
  },
  methods: {
    auditCb(data) {
      this.$emit("callback",data!=null);
      this.getData();
    },
    setexpenditure() {
      this.expenditureModal.visible = true;
      this.expenditureModal.info = this.form.expenditure;
    },
    expenditureModalCallBack(data) {
      if (data) {
        this.form.expenditure = data;
      }

      this.expenditureModal.visible = false;
    },
    gotoList() {
      this.$router.push({ path: "/business/publicbudgetlist" });
    },
    async showaudit() {
      let validRes = await this.$refs.publicBudgetForm.validate();
      if (!validRes) {
        this.$message.error("请填写必填项");
        return;
      }
      this.formValidPass = validRes;
      this.auditModal.info = this.form;
      this.auditModal.visible = true;
    },
    async auditCallBack(data) {
      let close = true;
      this.auditModal.visible = false;
      if (data != null && data.updateModel) {
        close = false;
        let validRes = await this.$refs.publicBudgetForm.validate();
        if (!validRes) {
          return false;
        }
        this.loading = true;
        this.loadingTip = "保存中...";
        let formData = {
          id: this.info.id,
          shixiang: this.form.shixiang,
          amount: this.form.amount,
          detail: this.form.detail,
          xiangmu: this.form.xiangmu,
          updateOnly: true,
        };
        let res = await publicbudgetApi.addOrUpdate(formData);
        if (res.errorCode == "0000") {
          this.loading = false;
          close = true;
        }
      }
      if (close) {
        this.$emit("callback", data != null);
      }
    },
    orgChanged(v, selectedOptions) {
      if (selectedOptions == null || selectedOptions.length == 0) {
        this.form.orgName = null;
        this.form.orgId = [];
      } else {
        this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
        this.form.orgId = v;
      }
      this.$forceUpdate();
    },
    onSearch(type) {
      this.selectuseModal.visible = true;
      this.selectuseModal.record = type;
    },
    selectuserCallback(user) {
      this.form[this.selectuseModal.record] = user.userName;
      this.form[this.selectuseModal.record + "Name"] = user.realName;
      this.selectuseModal.visible = false;
    },
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgs = res.data.tree;
          if (!this.info) {
            this.form.orgId = res.data.path;
            let tree = this.orgs;
            for (var i = 0; i < res.data.path.length; ++i) {
              var id = res.data.path[i];
              var find = tree.filter((n) => n.id == id);
              if (i == res.data.path.length - 1) {
                this.form.orgName = find[0].name;
              } else {
                tree = find[0].children;
              }
            }
            this.form.postUserName = this.form.borrowerName = res.data.realName;
            this.form.postUser = this.form.borrower = localStorage.getItem(
              this.$commons.User.userName
            );
          }
        }
      });
    },
    getData() {
      this.loading = true;
      publicbudgetApi.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.orgId = res.data.orgPath;
          delete res.data.orgPath;
          res.data.time = this.$moment(res.data.time, "YYYY-MM-DD");
          res.data.reportTime = this.$moment(res.data.reportTime, "YYYY-MM-DD");
          delete res.data.creator;
          delete res.data.createTime;
          delete res.data.creatorName;
          this.form = res.data;
          if (
            this.info &&
            this.info.taskId &&
            this.info.taskId != this.form.taskId
          ) {
            this.form.taskId = this.info.taskId;
          }
          if (res.data.status == 0 || res.data.status == 3) this.upLoadRemove = true;
          if (res.data.files) {
            var d = JSON.parse(res.data.files);
            var files = [];
            for (var i = 1; i <= d.length; ++i) {
              var a = d[i - 1];
              files.push({
                uid: i,
                name: a.title,
                status: "done",
                response: a,
                url: a.url,
              });
            }

            this.fileList = files;
          }
        }
      });
    },
    handleFileChange(fileInfo) {
      this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "error") {
        this.$message.error("上传失败");
      }
    },
    handleSubmit(status) {
      this.$refs.publicBudgetForm.validate((valid) => {
        if (valid) {
          let formData = { ...this.form, status };
          formData.orgId = formData.orgId[formData.orgId.length - 1];
          this.loading = true;
          if (this.info) {
            formData.id = this.info.id;
          }
          delete formData.auditRecords;
          formData.time = formData.time.format("YYYY-MM-DD");
          formData.reportTime = formData.reportTime.format("YYYY-MM-DD");
          if (this.fileList.length > 0) {
            var file = [];
            this.fileList.forEach((f) => {
              file.push({
                title: f.response.title,
                url: f.response.url,
              });
            });
            formData.files = JSON.stringify(file);
          }
          if (formData.amount < 2000) {
            this.$message.warning("预算金额不能低于2000！");
            this.loading = false;
            return false;
          }
          //添加
          publicbudgetApi
            .addOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == this.$commons.RespCode.success) {
                this.$message.success(res.errorMsg);
                this.form.id = res.data;
                if (this.modal) {
                  this.$emit("callback", true);
                } else {
                  this.gotoList();
                }
              } else {
                this.$message.error(res.errorMsg);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      this.$emit("callback", false);
    },
    showWps() {
      let templateId = "publicbudget";

      let innerData = Object.assign({}, this.form);
      delete innerData.auditRecords;
      delete innerData.processExecuteStatus;
      delete innerData.processId;
      delete innerData.processModelKey;
      delete innerData.processStatus;
      delete innerData.taskId;
      delete innerData.taskKey;
      delete innerData.taskRoles;
      delete innerData.taskUsers;
      delete innerData.status;
      delete innerData.creatorOrgId;
      innerData.reportTime =
        innerData.reportTime == null
          ? ""
          : this.$moment(innerData.reportTime, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            );
      innerData.time =
        innerData.time == null
          ? ""
          : this.$moment(innerData.time, "YYYY-MM-DD").format("YYYY年MM月DD日");
      if (innerData.postUserPic != null && innerData.postUserPic != "") {
        innerData.postUserPic =
          "http://localhost:9000/" +
          innerData.postUserPic.substring(innerData.postUserPic.indexOf("uas"));
      }
      if (this.fileList.length > 0) {
        var file = [];
        this.fileList.forEach((f) => {
          file.push(f.response.title);
        });
        innerData.files = file.join();
      }
      this.form.auditRecords.forEach((a) => {
        let key =
          "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
        if (a.taskPrintKey != null) {
          key = "taskKey" + a.taskPrintKey;
        }
        if (innerData[key] == null) {
          innerData[key] = [];
        }
        let auditPic = "";
        if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
          let s = a.auditUserSignPicture.indexOf("uas");
          auditPic =
            "http://localhost:9000/" +
            a.auditUserSignPicture.substring(
              a.auditUserSignPicture.indexOf("uas")
            );
        }
        a.auditUserSignPicture;
        let task = {
          auditRemark: a.auditRemark,
          auditPic: auditPic,
          auditName: a.auditUserName,
          auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
            "YYYY年MM月DD日"
          ),
        };
        innerData[key].push(task);
      });
      localStorage.setItem("innerData", JSON.stringify(innerData));
      localStorage.removeItem("publicFileId");
      const jump = this.$router.resolve({
        name: "wpsedit",
        query: {
          //要传的参数
          templateId: templateId,
          fileType: "public",
          openType: "create",
          fileName: "公用经费支出预算",
          tempUrl: "writeNumberTemp",
        },
      });

      window.open(jump.href, "_blank");
    },
    showWpsRead(file) {
      let type = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        type.toLowerCase().indexOf("doc") <= -1 &&
        type.toLowerCase().indexOf("xls") <= -1 &&
        type.toLowerCase().indexOf("ppt") <= -1
      ) {
        window.open(file.response.url, "_blank");
      } else {
        let fileId =
          "file" +
          file.url
            .substring(file.url.lastIndexOf("/") + 1, file.url.lastIndexOf("."))
            .replaceAll("-", "");
        let url = file.url.substring(file.url.indexOf("oabucket"));
        let type = file.url.substring(file.url.lastIndexOf(".") + 1);
        localStorage.removeItem("fileRrl");
        localStorage.setItem("fileRrl", url);
        localStorage.removeItem("fileName");
        localStorage.setItem("fileName", file.name);
        localStorage.removeItem("fileId");
        localStorage.setItem("fileId", fileId);
        localStorage.removeItem("fileTpye");
        localStorage.setItem("fileTpye", type);
        const jump = this.$router.resolve({
          name: "wpsRead",
          query: {
            //要传的参数
            fileName: file.name,
          },
        });

        window.open(jump.href, "_blank");
      }
    },
  },
};
</script>
