<template>
  <table>
    <tr v-for="(item, index) in datas" :key="item[columnName] + index">
      <td
        style="text-align: center; padding: 8px 16px"
        :style="
          index > 0 && index < datas.length
            ? 'border-top: 1px solid #e8e8e8;'
            : ''
        "
      >
        {{ item[columnName] }}
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "InnerTableForRowSpan",
  props: {
    records: {
      type: Array,
      required: true,
      default: () => [],
    },
    columnName: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      datas: [],
    };
  },
  mounted() {
    this.datas = this.records;
  },
  watch: {
    records: {
      handler: function (val) {
        this.datas = val;
      },
      immediate: true,
    },
  },
};
</script>

<style>
</style>