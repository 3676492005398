var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"tip":"加载中","spinning":_vm.isloading}},[_c('div',{staticStyle:{"height":"100%"}},[_c('a-row',[_c('a-col',{attrs:{"span":5}},[_c('a-divider',{attrs:{"orientation":"left"}},[_vm._v("组织机构")]),(_vm.orgTree.length > 0)?_c('a-tree',{staticStyle:{"overflow":"auto","height":"600px"},attrs:{"replaceFields":{
            title: 'name',
            key: 'id',
            children: 'children',
          },"selectedKeys":_vm.selectedKeys,"treeData":_vm.orgTree,"defaultExpandAll":true},on:{"select":_vm.onTypeSelect}}):_vm._e()],1),_c('a-col',{attrs:{"span":18}},[_c('div',{staticClass:"tableList"},[_c('div',{staticStyle:{"padding":"10px"}},[_c('a-form-model',{attrs:{"layout":"inline"}},[_c('a-form-model-item',{attrs:{"label":"姓名"}},[_c('a-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}})],1),_c('a-form-model-item',[_c('p-button',{attrs:{"type":"primary","name":"搜索"},on:{"click":_vm.searchList}})],1),_c('a-form-model-item',{staticStyle:{"text-align":"right"},attrs:{"label":"只显示分管部门"}},[_c('a-switch',{on:{"change":_vm.onlyLeadChanged},model:{value:(_vm.search.onlyLead),callback:function ($$v) {_vm.$set(_vm.search, "onlyLead", $$v)},expression:"search.onlyLead"}})],1)],1)],1),_c('div',[(_vm.multi)?_c('a-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.confirmselect}},[_vm._v("确认选择")]):_vm._e(),_c('a-table',{attrs:{"bordered":"","rowKey":"userName","size":"small","columns":_vm.list.columns,"pagination":_vm.list.pagination,"scroll":{ y: 550 },"rowSelection":_vm.multi
                  ? {
                      type: 'checkbox',
                      columnWidth: 40,
                      onChange: _vm.onRowChange,
                      selectedRowKeys: _vm.selectRowKeys,
                    }
                  : null,"dataSource":_vm.list.datas,"locale":{ emptyText: '暂无数据' }},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"status",fn:function(status){return _c('span',{},[(status == 1)?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("启用")]):(status == 0)?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("待审")]):_c('a-tag',{attrs:{"color":"red"}},[_vm._v("禁用")])],1)}},{key:"action",fn:function(action){return _c('span',{},[(!_vm.multi)?_c('p-button',{attrs:{"name":"选择","size":"small","type":"primary"},on:{"click":function($event){return _vm.selectuserinfo(action)}}}):_vm._e()],1)}}])})],1)])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }