import axios from "axios"
import router from "@/router"
import config from "@/config"
import commons from "@/utils/commons.js"
import { message } from "ant-design-vue";
axios.defaults.timeout = 30 * 1000;
axios.defaults.responseType = "json";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.headers.common["Cache-Control"] = "no-cache"; // 禁用缓存
axios.defaults.headers.common['appKey'] = 'oa';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = config.baseUrl;

// 请求拦截器
axios.interceptors.request.use((config) => {
    let token = localStorage.getItem(commons.User.Authentication);
    if (!token) {
        router.push({ path: "/login" });
    } else {
        config.headers.common['Authorization'] = 'Bearer ' + token;
    }
    if (config.params && config.params.appKey) {
        config.headers.common.appKey = config.params.appKey;
        delete config.params.appKey;
    }

    return config
}, (error) => {
    return Promise.reject(error);
});

// 响应拦截器
axios.interceptors.response.use((response) => {
    if (response.status != 200) {

        router.push({ path: "/login", query: { redirect: router.currentRoute.fullPath } });
        return response;
    }
    if (response.data == null && response.config.responseType === 'json' && response.request.responseText != null) {
        try {
            response.data = JSON.parse(response.request.responseText);
        } catch (e) {
            // ignored
        }
    }
    if (response.data.errorCode == commons.RespCode.needLogin) {
        let redirectPath = router.currentRoute.fullPath;
        if (redirectPath.indexOf("login") == -1) {
            router.push({ path: "/login", query: { redirect: router.currentRoute.fullPath } });
        }
        return response;
    } else if (response.data.errorCode == commons.RespCode.noAuthority) {
        message.error('没有操作权限');
        return response;
    }
    return response;
}, (error) => {
    if (error.response.status == 401) {
        let redirectPath = router.currentRoute.fullPath;
        if (redirectPath.indexOf("login") == -1) {
            router.push({ path: "/login", query: { redirect: router.currentRoute.fullPath } });
        }
    }
    return Promise.reject(error);
});

/**
 * http post request
 * @param {*} url
 * @param {*} params JSON格式
 * @param {*} data  body 提交数据为JSON格式
 */
const post = (url, params = {}, data = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            params: params,
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            resolve({ errorCode: "0003", errorMsg: err.message })
        })
    }).catch(e => {
        //message.error("系统异常");
    });
}

const upload = (url, params) => {
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: 'post',
            data: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            resolve({ errorCode: "0003", errorMsg: err.message })
        })
    }).catch(e => {
        //message.error("系统异常");
    });
}


/**
 * http get request
 * @param {*} url
 * @param {*} params
 */
const get = (url, params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            resolve({ errorCode: "0003", errorMsg: err.message })
        })
    }).catch(e => {
        ///message.error("系统异常");
    });
}
/**
 * http get request
 * @param {*} url
 * @param {*} params
 */
const getUseHeader = (url, params = {}, header = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
            headers: header
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            resolve({ errorCode: "0003", errorMsg: err.message })
        })
    }).catch(e => {
        ///message.error("系统异常");
    });
}


/**
 * http post request response blob
 * @param {*} url 
 * @param {*} data 
 */
const downloadFile = (url, data, fileName, onProgress, onError) => {
    var params = {
        ...data
    }

    var requestData = {
        method: 'get',
        params,
        url: url,
        responseType: "blob",
        onDownloadProgress: progress => {
            var complete = (Math.round(progress.loaded / progress.total * 100));
            onProgress(complete)
        },
        timeout: 0
    };
    axios(requestData).then(res => {
        const blob = new Blob([res.data]);
        if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            // +'.'+row.extType
            elink.style.display = "none";
            elink.href = window.URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
        } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
        }
    }).catch(() => {
        onError();
    });
}

const getUploadUrl = (dir) => {
    return config.fileServerUrl + "/oabucket/" + dir + "?appKey=oa&access_token=" + localStorage.getItem(commons.User.Authentication);
}
const isImageUrlValid = (imgurl) => {
    return new Promise(function (resolve, reject) {
        var imgObj = new Image();
        imgObj.src = imgurl;
        imgObj.onload = function (res) {
            resolve(res);
        }
        imgObj.onerror = function (err) {
            reject(err)
        }
    }).then(() => {
        console.log('有效链接')
    }).catch(() => {

        console.log('无效链接')
    })
}

export default {
    post,
    get,
    getUploadUrl,
    downloadFile,
    isImageUrlValid,
    uploadFile: `${config.fileServerUrl}`,
    uploadHeaders: {
        "Authorization": "Bearer " + localStorage.getItem(commons.User.Authentication),
        "appKey": "oa"
    },
    upload,
    getUseHeader
}