var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"left"}},[_c('ul',_vm._l((_vm.audits),function(item,ind){return _c('li',{key:item.id},[_c('div',{staticStyle:{"margin":"5px 0"}},[_c('div',[_vm._v(_vm._s(item.auditUserName))]),_c('a-upload',{attrs:{"action":_vm.uploadFileUrl,"fileList":item.fileList},on:{"change":function (fileInfo) { return _vm.handleChange(fileInfo, ind); },"preview":_vm.showWpsRead}})],1)])}),0),(_vm.uploadFile)?_c('div',{staticStyle:{"display":"flex","margin-top":"12px"}},[_c('a-upload',{attrs:{"action":_vm.uploadFileUrl,"fileList":_vm.fileList,"remove":function () { return _vm.upLoadRemove; }},on:{"change":_vm.handleFileChange,"preview":_vm.showWpsRead}},[(
          _vm.infoData.processExecuteStatus &&
          _vm.infoData.taskKey == _vm.taskKey &&
          _vm.isCurrentUser
        )?_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v("选择附件")],1):_vm._e()],1),(
        _vm.infoData.processExecuteStatus &&
        _vm.infoData.taskKey == _vm.taskKey &&
        _vm.isCurrentUser
      )?_c('a-button',{staticStyle:{"margin-left":"12px"},attrs:{"type":"primary"},on:{"click":_vm.uploadDoc}},[_vm._v(" "+_vm._s(_vm.btnText)+" ")]):_vm._e()],1):_vm._e(),(
      _vm.infoData.processExecuteStatus &&
      _vm.infoData.taskKey == _vm.taskKey &&
      _vm.isCurrentUser
    )?_c('a-button',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"type":"link"},on:{"click":_vm.showaudit}},[_vm._v(" 流转 ")]):_vm._e(),_c('a-modal',{attrs:{"title":"流转到下一节点","width":"40%","footer":false,"destroyOnClose":"","maskClosable":false},model:{value:(_vm.auditModal.visible),callback:function ($$v) {_vm.$set(_vm.auditModal, "visible", $$v)},expression:"auditModal.visible"}},[_c('auditone',{attrs:{"info":_vm.auditModal.info},on:{"callback":_vm.auditCallBack}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }