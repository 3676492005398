<template>
  <div class="tableList">
    <div class="search">
      <div class="search-body">
        <a-button type="primary" @click="addOrUpdate(null, 'edit')"
          >新增</a-button
        >
      </div>
    </div>
    <div class="tableItem">
      <a-table
          bordered
        rowKey="id"
        :columns="tableInfo.columns"
        :data-source="tableInfo.datas"
        :pagination="false"
        :loading="loading"
        :locale="{ emptyText: '暂无数据' }"
      >
        <template slot="status" slot-scope="status">
          <a-tag color="red" v-if="status == 0">未发布</a-tag>
          <a-tag color="green" v-if="status == 1">已发布</a-tag>
        </template>
        <span slot="action" slot-scope="action">
          <a-button
            @click="addOrUpdate(action, 'edit')"
            size="small"
            type="primary"
            v-if="action.status == 0"
            >修改</a-button
          >

          <a-popconfirm
            title="发布后数据不可更改，是否确认？"
            @confirm="publish(action.id)"
            v-if="action.status == 0"
          >
            <a-button size="small" type="danger">发布</a-button>
          </a-popconfirm>
          <a-button
            @click="addOrUpdate(action, 'show')"
            size="small"
            type="primary"
            v-if="action.status == 1"
            >查看</a-button
          >
          <a-button @click="copy(action)" size="small" type="primary"
            >复制</a-button
          >
          <a-dropdown>
            <a-button style="margin-left: 8px" size="small">
              其它 <a-icon type="down" />
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item key="1" v-if="action.status == 0">
                <a-popconfirm
                  title="确认删除当前流程定义信息吗？"
                  @confirm="remove(action.id)"
                >
                  <a-button size="small" type="link">删除</a-button>
                </a-popconfirm>
              </a-menu-item>
              <a-menu-item key="2">
                <a-button
                  size="small"
                  type="link"
                  @click="showhistory(action.processModelKey)"
                  >历史版本</a-button
                >
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>
    <a-modal
      title="流程定义"
      v-model="editor.visible"
      :dialog-style="{ top: '0' }"
      :bodyStyle="{ height: 'calc(100vh - 55px)' }"
      class="defintion-modal"
      width="90%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <editdefinition :info="editor.info" @callback="modelCallBack" />
    </a-modal>
    <a-modal
      title="历史版本"
      v-model="historyModal.visible"
      width="90%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <historyVue
        :processModelKey="historyModal.processModelKey"
        @callback="modelCallBack"
      />
    </a-modal>
  </div>
</template>
  
  <script>
import workflowApi from "@/api/workflow";
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
  },
  {
    dataIndex: "name",
    key: "name",
    align: "center",
    title: "名称",
  },
  {
    dataIndex: "description",
    key: "description",
    align: "center",
    title: "描述",
  },
  {
    dataIndex: "status",
    key: "status",
    align: "center",
    title: "状态",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

import editdefinition from "./editdefinition";
import historyVue from "./history.vue";
export default {
  name: "workflow",
  data() {
    return {
      loading: false,
      tableInfo: {
        datas: [],
        columns,
      },
      editor: {
        title: "编辑",
        visible: false,
        info: {},
      },
      historyModal: {
        visible: false,
        processModelKey: null,
      },
    };
  },
  components: {
    editdefinition,
    historyVue,
  },
  mounted() {
    this.getList();
  },
  methods: {
    showhistory(k) {
      this.historyModal.processModelKey = k;
      this.historyModal.visible = true;
    },
    async getList() {
      workflowApi.getList().then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          this.tableInfo.datas = res.data;
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    remove(id) {
      workflowApi.remove(id).then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    publish(id) {
      workflowApi.publish(id).then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success("操作成功");
          this.getList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    addOrUpdate(info, action) {
      let _this = this;
      _this.editor.visible = true;
      _this.editor.info = { ...info, action };
    },
    copy(info) {
      let _this = this;
      _this.editor.info = { ...info, action: "copy" };
      _this.editor.visible = true;
    },
    modelCallBack() {
      this.editor.visible = false;
      this.getList();
    },
  },
};
</script>
  
<style lang="less">
.defintion-modal .ant-modal-wrap {
  overflow: hidden;
}
</style>