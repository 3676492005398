<template>
  <a-spin tip="加载中" :spinning="isloading">
    <div style="height: 100%">
      <a-row>
        <a-col :span="5">
          <a-divider orientation="left">组织机构</a-divider>
          <a-tree
            :replaceFields="{
              title: 'name',
              key: 'id',
              children: 'children',
            }"
            :selectedKeys="selectedKeys"
            v-if="orgTree.length > 0"
            :treeData="orgTree"
            :defaultExpandAll="true"
            style="overflow: auto; height: 600px"
            @select="onTypeSelect"
          />
        </a-col>
        <a-col :span="18">
          <div class="tableList">
            <div style="padding: 10px">
              <a-form-model layout="inline">
                <a-form-model-item label="姓名">
                  <a-input placeholder="请输入" v-model="search.name" />
                </a-form-model-item>

                <a-form-model-item>
                  <p-button type="primary" @click="searchList" name="搜索" />
                </a-form-model-item>
                <a-form-model-item
                  style="text-align: right"
                  label="只显示分管部门"
                >
                  <a-switch
                    v-model="search.onlyLead"
                    @change="onlyLeadChanged"
                  />
                </a-form-model-item>
              </a-form-model>
            </div>

            <div>
              <a-button
                v-if="multi"
                type="primary"
                size="small"
                @click="confirmselect"
                style="margin-bottom: 10px"
                >确认选择</a-button
              >
              <a-table
          bordered
                rowKey="userName"
                size="small"
                :columns="list.columns"
                :pagination="list.pagination"
                @change="onPageChange"
                :scroll="{ y: 550 }"
                :rowSelection="
                  multi
                    ? {
                        type: 'checkbox',
                        columnWidth: 40,
                        onChange: onRowChange,
                        selectedRowKeys: selectRowKeys,
                      }
                    : null
                "
                :dataSource="list.datas"
                :locale="{ emptyText: '暂无数据' }"
              >
                <span slot="status" slot-scope="status">
                  <a-tag v-if="status == 1" color="green">启用</a-tag>
                  <a-tag v-else-if="status == 0" color="green">待审</a-tag>
                  <a-tag v-else color="red">禁用</a-tag>
                </span>
                <span slot="action" slot-scope="action">
                  <p-button
                    v-if="!multi"
                    name="选择"
                    @click="selectuserinfo(action)"
                    size="small"
                    type="primary"
                  />
                </span>
              </a-table>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-spin>
</template>

<script>
import orgApi from "@/api/org";
const columns = [
  {
    dataIndex: "userName",
    align: "left",
    title: "用户名",
  },

  {
    dataIndex: "realName",
    align: "center",
    title: "姓名",
  },
  {
    dataIndex: "telephone",
    align: "center",
    title: "手机号",
  },

  {
    dataIndex: "regTime",
    align: "center",
    title: "创建时间",
  },
  {
    dataIndex: "status",
    align: "center",
    title: "状态",
    scopedSlots: { customRender: "status" },
  },
];
let _this;
export default {
  name: "selectuser",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 100,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 310,
      },
      orgTree: [],
      selectedKeys: [],
      selectRows: [],
      selectRowKeys: [],
      rowSelection: null,
      search: {
        name: "",
        onlyLead: false,
      },
      isloading: false,
    };
  },
  props: {
    multi: {
      type: Boolean,
      default: false,
    },
    column: {
      type: String,
    },
    checkUsers: {
      type: String,
      default: "",
    },
  },
  created() {
    if (!this.multi) {
      this.list.columns.push({
        title: "操作",
        key: "action",
        align: "center",
        scopedSlots: { customRender: "action" },
      });
    }
  },
  mounted() {
    _this = this;
    if (this.multi) {
      this.rowSelection = {
        type: "checkbox",
        columnWidth: 40,
        onChange: this.onRowChange,
        selectedRowKeys: this.selectRowKeys,
      };
    }
    _this.getOrgTree();
    _this.GetList();
  },
  beforeDestroy() {
    if (this.list.columns.find((item) => item.key == "action")) {
      this.list.columns.splice(this.list.columns.length - 1, 1);
    }
  },
  methods: {
    onlyLeadChanged(v) {
      this.search.onlyLead = v;
      this.search.orgId = null;
      this.selectedKeys = [];
      _this.getOrgTree();
      _this.GetList();
    },
    confirmselect() {
      this.$emit("callback", this.selectRows, this.column);
    },
    onRowChange(selectedRowKeys, rows) {
      this.selectRowKeys = selectedRowKeys;
      var users = this.list.datas.map((item) => item.userName);

      for (var i = 0; i < users.length; ++i) {
        var user = users[i];
        for (var j = 0; j < this.selectRows.length; ++j) {
          if (this.selectRows[j].userName == user) {
            this.selectRows.splice(j, 1);
            break;
          }
        }
      }
      this.selectRows = [];

      if (selectedRowKeys != null) {
        for (var k = 0; k < rows.length; ++k) {
          this.selectRows.push({
            userName: rows[k].userName,
            realName: rows[k].realName,
          });
        }
      }
    },
    async getOrgTree() {
      _this.isloading = true;
      let result = await orgApi.getTree(false, false, this.search.onlyLead);
      _this.isloading = false;
      if (result.errorCode == _this.$commons.RespCode.success) {
        _this.orgTree = result.data;
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    onTypeSelect(selectKeys) {
      _this.search.orgId = selectKeys[0];
      _this.selectedKeys = selectKeys;
      _this.GetList();
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      orgApi
        .getUsersForOrg(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          { ..._this.search }
        )
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.list.datas = res.data.records;
            _this.selectRowKeys = [];
            if (_this.checkUsers != "") {
              console.log("checkUsers", _this.checkUsers);

              _this.selectRows = [];
              let users = _this.checkUsers.split(",");
              for (var i = 0; i < users.length; ++i) {
                var user = users[i];
                _this.selectRows.push({
                  userName: user,
                  realName: user,
                });
              }
            }
            _this.list.datas.forEach((item) => {
              var f = _this.selectRows.filter(
                (i) =>
                  i.realName == item.realName || i.userName == item.userName
              );
              if (f.length > 0) {
                _this.selectRowKeys.push(item.userName);
              }
            });
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      this.GetList();
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    selectuserinfo(user) {
      this.$emit("callback", user, this.column);
    },
  },
};
</script>

<style></style>
