<template>
  <a-spin tip="加载中" :spinning="loading">
    <div>
      <a-form-model
        :model="form"
        :rules="rules"
        ref="newsCategoryForm"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <table class="formtable">
          <tr>
            <td style="width: 100px">部室</td>
            <td style="text-align: left">
              <a-cascader
                :options="orgs"
                v-model="form.orgId"
                @change="orgChanged"
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
                placeholder="请选择部室"
              />
            </td>
            <td>日期</td>
            <td style="text-align: left">
              <a-date-picker v-model="form.time" valueFormat="YYYY-MM-DD" />
            </td>
          </tr>
          <tr>
            <td>经办人</td>
            <td style="text-align: left">
              <a-input-search
                class="select_search"
                readOnly
                placeholder="请选择"
                v-model="form.handlerName"
                @click="onSearch"
                @search="onSearch"
              >
                <a-button
                  type="primary"
                  slot="enterButton"
                  icon="plus"
                  title="添加"
                />
              </a-input-search>
            </td>
            <td>部室主任(签字)</td>
            <td style="text-align: left">
              <auditshow
                :infoData="form"
                taskKey="文印-部室主任(签字)"
                @callback="auditCb"
              />
            </td>
          </tr>
          <tr>
            <td colspan="4" style="padding: 0px">
              <a-table
                bordered
                rowKey="id"
                :columns="columns"
                :data-source="form.items"
                :pagination="false"
                :locale="{ emptyText: '暂无数据' }"
              >
                <span slot="title1" slot-scope="data">
                  <a-input v-model="data.title" />
                </span>

                <span slot="count" slot-scope="data">
                  <a-input-number v-model="data.count" />
                </span>
                <span slot="remark" slot-scope="data">
                  <a-input v-model="data.remark" />
                </span>
                <span slot="action" slot-scope="text, row, index">
                  <a-popconfirm
                    title="确定要删除吗？"
                    @confirm="removeItem(index)"
                  >
                    <a-button
                      size="small"
                      type="link"
                      v-if="form.status == 0 || form.status == 3"
                      >删除</a-button
                    >
                  </a-popconfirm>
                </span>
                <template
                  slot="footer"
                  v-if="form.status == 0 || form.status == 3"
                >
                  <a-button style="float: left" @click="addItem">
                    <a-icon type="plus" />
                    新增</a-button
                  >
                </template>
              </a-table>
            </td>
          </tr>
        </table>

        <a-form-model-item
          :wrapper-col="{ span: 10, offset: 10 }"
          style="margin-top: 10px"
        >
          <a-button
            v-if="form.status == 0 || form.status == 3"
            type="primary"
            @click="handleSubmit(0)"
            style="margin-right: 10px"
            >保存</a-button
          >
          <a-popconfirm
            title="提交后数据不可更改，是否确认？"
            @confirm="handleSubmit(1)"
            v-if="form.status == 0 || form.status == 3"
          >
            <a-button type="danger" style="margin-right: 10px">提交</a-button>
          </a-popconfirm>
          <a-button
            type="primary"
            v-if="form.processExecuteStatus && form.taskKey == '其他-验收'"
            style="margin-right: 10px"
            @click="showaudit()"
            >验收</a-button
          >
          <a-button
            v-if="this.form.status == 2"
            @click="showWps"
            style="margin-right: 10px"
            >打印</a-button
          >
          <a-button
            type="primary"
            v-if="form.status == 2 && allowFinish"
            style="margin-right: 10px"
            @click="finishTask()"
            >办理</a-button
          >
          <a-button @click="cancelClick">取消</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal
      title="选择用户"
      v-model="selectuservisible"
      width="70%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <selectuser @callback="selectuserCallback" />
    </a-modal>
    <a-modal
      title="审批"
      v-model="auditModal.visible"
      width="40%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
  </a-spin>
</template>
<script>
import documentprintApi from "@/api/work/documentprint";
import selectuser from "../components/searchuser.vue";
import orgApi from "@/api/org";
import workflowApi from "@/api/workflow";
import workflowapi from "@/api/workflow";
import pinyin from "js-pinyin";
const columns = [
  {
    key: "index",
    align: "center",
    title: "序号",
    width: 70,
    customRender: (text, record, index) => {
      return index + 1;
    },
  },
  {
    key: "title",
    align: "center",
    title: "文印标题",
    scopedSlots: { customRender: "title1" },
  },
  {
    key: "count",
    align: "center",
    title: "份数",
    scopedSlots: { customRender: "count" },
  },
  {
    key: "remark",
    align: "center",
    title: "备注（必填，请填写印制要求等）",
    scopedSlots: { customRender: "remark" },
  },

  {
    title: "操作",
    key: "action",
    width: 120,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "createdocumentprint",
  data() {
    return {
      loading: false,
      form: {
        time: null,
        status: 0,
        items: [{}],
      },
      auditModal: {
        info: null,
        visible: false,
      },
      taskId: "",
      allowFinish: false,
      columns,
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      orgs: [],
      selectuservisible: false,
      rules: {
        time: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入备注",
            trigger: "blur",
          },
        ],
      },
      currUser: localStorage.getItem(this.$commons.User.userName),
    };
  },
  props: {
    info: {
      type: Object,
    },
  },
  mounted() {
    this.getOrgTree();
    if (this.info && this.info.id > 0) {
      this.getData();
    }
  },
  components: {
    selectuser,
  },
  methods: {
    showaudit() {
      this.auditModal.info = this.form;
      this.auditModal.visible = true;
    },
    auditCallBack(data) {
      this.auditModal.visible = false;
      this.$emit("callback", data != null);
    },
    orgChanged(v, selectedOptions) {
      if (selectedOptions == null || selectedOptions.length == 0) {
        this.form.orgName = null;
        this.form.orgId = [];
      } else {
        this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
        this.form.orgId = v;
      }
      this.$forceUpdate();
    },
    onSearch() {
      this.selectuservisible = true;
    },
    selectuserCallback(user) {
      this.form.handler = user.userName;
      this.form.handlerName = user.realName;
      this.selectuservisible = false;
    },
    addItem() {
      this.form.items.push({});
    },
    removeItem(index) {
      this.form.items.splice(index, 1);
    },

    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgs = res.data.tree;
          if (!this.info) {
            this.form.orgId = res.data.path;
            let tree = this.orgs;
            for (var i = 0; i < res.data.path.length; ++i) {
              var id = res.data.path[i];
              var find = tree.filter((n) => n.id == id);
              if (i == res.data.path.length - 1) {
                this.form.orgName = find[0].name;
              } else {
                tree = find[0].children;
              }
            }
            this.form.handlerName = res.data.realName;
            this.form.handler = localStorage.getItem(
              this.$commons.User.userName
            );
          }
        }
      });
    },
    async finishTask() {
      if (this.taskId == "" || this.taskId == null) {
        this.$message.error("已办理");
        return;
      }
      this.worktaskloading = true;
      var res = await workflowApi.finishTask(this.taskId);
      this.worktaskloading = false;
      if (res.errorCode === this.$commons.RespCode.success) {
        this.$message.success("操作成功");
        this.getData();
      } else {
        this.$message.error(res.errorMsg);
      }
    },
    getTask(id) {
      this.loading = true;
      this.taskId = "";
      this.allowFinish = false;
      workflowapi.getTask(id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          if (res.data != null && res.data != "" && res.data.length > 0) {
            this.taskId = res.data[0].id;
            this.allowFinish = true;
            this.$forceUpdate();
          }
        }
      });
    },
    getData() {
      this.loading = true;
      documentprintApi.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.orgId = res.data.orgPath;
          delete res.data.orgPath;
          this.form = res.data;
          this.getTask(res.data.processId);
          if (
            this.info &&
            this.info.taskId &&
            this.info.taskId != this.form.taskId
          ) {
            this.form.taskId = this.info.taskId;
          }
        }
      });
    },
    handleSubmit(status) {
      let isPass = this.form.items.filter(
        (item) => item.remark == "" || !item.remark
      );
      if (isPass.length > 0) {
        this.$message.warning("备注不能为空！");
        return false;
      }
      this.$refs.newsCategoryForm.validate((valid) => {
        if (valid) {
          let formData = { ...this.form, status };
          formData.orgId = formData.orgId[formData.orgId.length - 1];
          this.loading = true;
          if (this.info) {
            formData.id = this.info.id;
          }
          delete formData.createTime;
          delete formData.creator;
          delete formData.creatorName;
          delete formData.auditRecords;
          //添加
          documentprintApi
            .addOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == this.$commons.RespCode.success) {
                this.$message.success(res.errorMsg);
                this.$emit("callback", true);
              } else {
                this.$message.error(res.errorMsg);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      this.$emit("callback", false);
    },
    auditCb(data) {
      this.$emit("callback", data != null);
      this.getData();
    },
    showWps() {
      // let templateId = 'documentprint';
      let templateId = "documentprint";

      let innerData = Object.assign({}, this.form);
      delete innerData.auditRecords;
      delete innerData.processExecuteStatus;
      delete innerData.processId;
      delete innerData.processModelKey;
      delete innerData.processStatus;
      delete innerData.taskId;
      delete innerData.taskKey;
      delete innerData.taskRoles;
      delete innerData.taskUsers;
      delete innerData.status;
      delete innerData.creatorOrgId;
      innerData.notInsertTableRow = "true";
      innerData.time =
        innerData.time == null
          ? ""
          : this.$moment(innerData.time, "YYYY-MM-DD").format("YYYY年MM月DD日");
      if (this.form.items != null && this.form.items.length > 0) {
        let i = 0;
        this.form.items.forEach((a) => {
          a.sort = ++i;
        });
        delete innerData.items;
        innerData.list = this.form.items;
      }

      if (innerData.handlerPic != null && innerData.handlerPic != "") {
        innerData.handlerPic =
          "http://localhost:9000/" +
          innerData.handlerPic.substring(innerData.handlerPic.indexOf("uas"));
      }

      this.form.auditRecords.forEach((a) => {
        let key =
          "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
        if (a.taskPrintKey != null) {
          key = "taskKey" + a.taskPrintKey;
        }
        if (innerData[key] == null) {
          innerData[key] = [];
        }
        let auditPic = "";
        if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
          auditPic =
            "http://localhost:9000/" +
            a.auditUserSignPicture.substring(
              a.auditUserSignPicture.indexOf("uas")
            );
        }
        let task = {
          auditRemark: a.auditRemark,
          auditPic: auditPic,
          auditName: a.auditUserName,
          auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
            "YYYY年MM月DD日"
          ),
        };
        innerData[key].push(task);
      });
      localStorage.setItem("innerData", JSON.stringify(innerData));
      localStorage.removeItem("publicFileId");
      const jump = this.$router.resolve({
        name: "wpsedit",
        query: {
          //要传的参数
          templateId: templateId,
          fileType: "public",
          openType: "create",
          fileName: "邮寄服务",
          tempUrl: "writeNumberTemp",
        },
      });

      window.open(jump.href, "_blank");
    },
  },
};
</script>
