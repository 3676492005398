<template>
  <div class="tableList">
    <div class="search">
      <div class="search-body">
        <a-button style="float: right" @click="gotoList">返回</a-button>
      </div>
    </div>
    <a-row>
      <a-col style="padding: 50px" :span="10">
        <a-row>
          <a-col :span="12">
            <h2>请示报告</h2>
          </a-col>
          <a-col :span="12">
            <a-icon type="file-word" style="color: #3299ff; font-size: 60px" />
          </a-col>
        </a-row>
        <span>根据报告类型选择以下按钮进行选择</span>
        <a-row>
          <a-col style="padding-top: 50px">
            <a-button
              type="primary"
              size="large"
              @click="add('请示')"
              style="margin: 20px"
              >请示</a-button
            >
            <p-button
              type="primary"
              size="large"
              @click="add('党支部请示')"
              style="margin: 20px"
              name="党支部请示"
              :roles="['发文-党支部请示新增']"
            />
            <a-button
              type="primary"
              size="large"
              @click="add('报告')"
              style="margin: 20px"
              >报告</a-button
            >
            <a-button
              type="primary"
              size="large"
              @click="add('省教科院通知')"
              style="margin: 20px"
              >省教科院通知</a-button
            >
            <p-button
              type="primary"
              size="large"
              @click="add('省规划办通知')"
              style="margin: 20px"
              name="省规划办通知"
              :roles="['发文-省规划办通知新增']"
            />
            <p-button
              type="primary"
              size="large"
              @click="add('省教育学会通知')"
              style="margin: 20px"
              name="省教育学会通知"
              :roles="['发文-省教育学会通知新增']"
            />
            <p-button
              type="primary"
              size="large"
              @click="add('会议纪要')"
              style="margin: 20px"
              name="会议纪要"
              :roles="['发文-会议纪要新增']"
            />
            <p-button
              type="primary"
              size="large"
              @click="add('支部工作动态')"
              style="margin: 20px"
              name="支部工作动态"
              :roles="['发文-支部工作动态新增']"
            />
          </a-col>
        </a-row>
      </a-col>
      <a-col style="padding: 50px" :span="10">
        <div>
          <a-row>
            <a-col :span="12">
              <h2>发文卡</h2>
            </a-col>
            <a-col :span="12">
              <a-icon type="profile" style="color: #3299ff; font-size: 60px" />
            </a-col>
          </a-row>

          <span>根据发文单位选择以下按钮进行选择</span>
          <a-row>
            <a-col style="padding-top: 50px">
              <a-button
                type="primary"
                size="large"
                @click="add('省教科院发文卡')"
                style="margin: 20px"
                >省教科院发文卡</a-button
              >
              <p-button
                type="primary"
                size="large"
                @click="add('省规划办发文卡')"
                style="margin: 20px"
                name="省规划办发文卡"
                :roles="['发文-省规划办发文卡新增']"
              />
              <p-button
                type="primary"
                size="large"
                @click="add('省教育学会发文卡')"
                style="margin: 20px"
                name="省教育学会发文卡"
                :roles="['发文-省教育学会发文卡新增']"
              />
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
    <a-modal
      :title="title"
      v-model="visible"
      :dialog-style="{ top: '20px' }"
      :width="t == 'fawen' ? '90%' : '70%'"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <qingshi v-if="t == 'qingshi'" :info="info" @callback="addCallback" />
      <huiyijiyao
        v-if="t == 'huiyijiyao'"
        :info="info"
        @callback="addCallback"
      />
      <zhibugongzuo
        v-if="t == 'zhibugongzuo'"
        :info="info"
        @callback="addCallback"
      />
      <fawen v-if="t == 'fawen'" :info="info" @callback="addCallback" />
    </a-modal>
  </div>
</template>
<script>
import qingshi from "./qingshi.vue";
import fawen from "./fawen.vue";
import huiyijiyao from "./huiyijiyao.vue";
import zhibugongzuo from "./zhibugongzuo.vue";
export default {
  name: "newdocument",
  data() {
    return {
      visible: false,
      title: "",
      info: {},
      t: "",
    };
  },
  components: { qingshi, fawen, huiyijiyao, zhibugongzuo },
  methods: {
    add(type) {
      this.title = type;
      this.info.type = type;
      if (
        type == "请示" ||
        type == "报告" ||
        type.indexOf("通知") > -1 ||
        type == "党支部请示"
      ) {
        this.t = "qingshi";
      } else if (type == "会议纪要") {
        this.t = "huiyijiyao";
      } else if (type == "支部工作动态") {
        this.t = "zhibugongzuo";
      } else {
        this.t = "fawen";
      }
      this.visible = true;
    },
    addCallback() {
      this.visible = false;
    },
    gotoList() {
      this.$router.push({ path: "/business/documentdispatch" });
    },
  },
};
</script>