class WebSocketClient {

    constructor(url, msgCallback) {
        this.ws = null;
        this.url = url;
        this.msgCallback = msgCallback;
        this.heartbeatInterval = 30000;
        this.heartbeatTimer = null;
        this.connect();
        this.reconnectTimes = 0;
    }

    connect() {
        if ("WebSocket" in window) {
            this.ws = new WebSocket(this.url);
            this.ws.onopen = () => {
                if (this.ws.readyState === WebSocket.OPEN) {
                    this.startHeartbeat();
                } else {
                    this.connect();
                }
            };

            this.ws.onclose = (e) => {
                this.stopHeartbeat();
                if (e.code == 1002 && this.reconnectTimes < 5) {
                    this.connect();
                    this.reconnectTimes++;
                }
            };

            this.ws.onerror = (e) => {
                console.error('WebSocket error:', e);
            };

            this.ws.onmessage = (e) => {
                this.handleMessage(e.data, this.msgCallback);
            };

        }
    }

    startHeartbeat() {
        this.heartbeatTimer = setInterval(() => {
            if (this.ws.readyState === WebSocket.OPEN) {
                try {
                    this.ws.send(JSON.stringify({ type: 'PING' }));
                } catch (error) {
                    this.connect();
                }
            }
        }, this.heartbeatInterval);
    }

    stopHeartbeat() {
        if (this.heartbeatTimer) {
            clearInterval(this.heartbeatTimer);
            this.heartbeatTimer = null;
        }
    }

    handleMessage(message, msgCallback) {
        const data = JSON.parse(message);
        switch (data.type) {
            case 'PING':
                console.debug('Heartbeat received');
                break;
            case 'TEXT':
                console.log('TEXT message received');
                msgCallback(data.content);
                break;
            default:
                console.log('Unknown message type:', data.type);
        }
    }
    close() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }
    }
}

export const intiWebsocket = (url, msgCallback) => {
    return new WebSocketClient(url, msgCallback);
}
