<template>
  <div>
    <a-tag color="red" v-if="processModel.status == 0">待提交</a-tag>
    <a-tag color="red" v-else-if="processModel.status == 3" @click="showflow"
      >未通过</a-tag
    >
    <a-tag color="green" v-else-if="processModel.status == 2" @click="showflow"
      >通过</a-tag
    >
    <template v-else>
      <a-tag color="green" style="cursor: pointer" @click="showflow">
        {{ processModel.currentNodeName }}
      </a-tag>
    </template>
    <a-drawer
      title="流程"
      placement="right"
      :closable="false"
      :visible="visible"
      :width="800"
      destroyOnClose
      @close="() => (visible = false)"
    >
      <workflowdetail :info="processModel" />
    </a-drawer>
  </div>
</template>
<script>
import workflowdetail from "./workflowdetail.vue";
export default {
  name: "processstatus",
  props: {
    processModel: {
      type: Object,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  components: { workflowdetail },
  methods: {
    showflow() {
      this.visible = true;
    },
  },
};
</script>