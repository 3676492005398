<template>
  <a-spin :spinning="loading" :tip="loadingTip">
    <div class="tableList">
      <p-button
        type="primary"
        style="margin-right: 10px"
        @click="addOrUpdate(null)"
        :roles="['每周菜谱-发布']"
        name="新增"
      />
      <div class="tableItem">
        <a-table
          bordered
          rowKey="id"
          :columns="tableInfo.columns"
          :data-source="tableInfo.datas"
          :pagination="tableInfo.pagination"
          @change="onChange"
          :locale="{ emptyText: '暂无数据' }"
          :scroll="{ y: tableInfo.tableHeight }"
        >
          <template slot="status" slot-scope="info">
            <processStatus :processModel="info" />
          </template>
          <span slot="action" slot-scope="action">
            <a-button
              @click="addOrUpdate(action)"
              size="small"
              type="primary"
              v-if="action.status == 0"
              >修改</a-button
            >

            <a-popconfirm
              title="提交后数据不可更改，是否确认？"
              @confirm="changeStatus(action.id, 1)"
              v-if="action.status == 0"
            >
              <a-button size="small" type="danger">提交</a-button>
            </a-popconfirm>

            <a-button
              @click="addOrUpdate(action)"
              size="small"
              type="primary"
              v-if="action.processExecuteStatus"
              >审批</a-button
            >
            <a-button @click="addOrUpdate(action)" size="small" type="default"
              >详情</a-button
            >
          </span>
        </a-table>
      </div>
      <a-modal
        :title="editor.title"
        v-model="editor.visible"
        width="90%"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <cookbook :info="editor.info" @callback="modelCallBack" />
      </a-modal>
    </div>
  </a-spin>
</template>
  
  <script>
import cookbookApi from "@/api/personal/cookbook";
import cookbook from "./cookbookinfo.vue";
const columns = [
  {
    key: "month",
    dataIndex: "month",
    align: "center",
    title: "月份",
  },
  {
    dataIndex: "num",
    key: "num",
    align: "center",
    title: "周",
  },

  {
    key: "status",
    align: "center",
    scopedSlots: { customRender: "status" },
    title: "状态",
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "cookbooklist",
  data() {
    return {
      loading: false,
      loadingTip: "加载中",
      search: {},
      tableInfo: {
        datas: [],
        columns,
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          defaultCurrent: 1,
          current: 1,
          defaultPageSize: 10,
          showTotal: (total) => `总共${total}条`,
        },
      },
      selectRows: [],
      editor: {
        title: "编辑",
        visible: false,
        info: {},
      },
    };
  },
  components: {
    cookbook,
  },
  mounted() {
    this.getPageList();
  },
  methods: {
    changeStatus(id, status) {
      this.loading = true;
      cookbookApi.changeStatus(id, status).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.getPageList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },

    async getPageList() {
      let _this = this;
      let param = {
        ...this.search,
        pageNow: _this.tableInfo.pagination.current,
        pageSize: _this.tableInfo.pagination.defaultPageSize,
      };
      cookbookApi.getList(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.tableInfo.datas = res.data.records;
          _this.tableInfo.pagination.total = Number(res.data.total);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },

    onChange(page) {
      let _this = this;
      _this.tableInfo.pagination.current = page.current;
      _this.getPageList();
    },

    addOrUpdate(info) {
      let _this = this;
      _this.editor.visible = true;
      if (info) {
        _this.editor.title = "编辑";
        _this.editor.info = info;
      } else {
        _this.editor.title = "新增";
        info = {
          id: 0,
        };
        _this.editor.info = info;
      }
    },

    modelCallBack(f) {
      this.editor.visible = false;
      this.getPageList();
    },
  },
};
</script>
  
  <style lang="less">
</style>