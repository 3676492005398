<template>
  <div style="text-align: left">
    <a-select v-model="number.han" style="width: 35%" @change="numberChanged">
      <a-select-option
        v-for="item in items"
        :key="item.id"
        :value="item.itemValue"
      >
        {{ item.itemName }}
      </a-select-option>
    </a-select>
    <a-select v-model="number.year" style="width: 25%" @change="yearChanged">
      <a-select-option v-for="item in years" :key="item" :value="item">
        {{ item }}
      </a-select-option>
    </a-select>
    <a-input v-model="number.no" style="width: 20%"></a-input>
    <span v-if="ty == '会议纪要'" style="width: 10%">
      第 <a-input v-model="number.qi" style="width: 10%"></a-input> 期</span
    >
  </div>
</template>
<script>
import dictionaryItemApi from "@/api/system/dictionaryItem";
import documentdispatchApi from "@/api/work/documentdispatch";
export default {
  name: "documentnumber",
  data() {
    return {
      items: [],
      years: [],
      number: {
        han: "",
        year: "",
        no: "",
        qi: "",
      },
    };
  },
  props: {
    v: String,
    ty: String,
  },
  mounted() {
    var d = new Date();
    var y = d.getFullYear();
    for (var i = -1; i < 3; ++i) {
      this.years.push(y - i);
    }
    if (this.ty) {
      this.setTy();
    }
  },
  watch: {
    v() {
      this.setValue();
    },
    ty() {
      this.setTy();
    },
  },
  methods: {
    setValue() {
      if (this.v) {
        var arr = this.v.split("-");
        this.number.han = arr[0];
        this.number.year = arr[1];
        if (arr[2] != null) this.number.no = arr[2];
        if (arr[3] != null) this.number.qi = arr[3];
      }
      this.$forceUpdate();
    },
    setTy() {
      dictionaryItemApi.getItemByDictionaryName(this.ty).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.items = res.data;
          if (this.number.han == "") {
            this.number.han = res.data[0].itemValue;
            this.numberChanged(this.number.han);
          }
          this.setValue();
        }
      });
    },
    getNumber() {
      let number =
        this.number.han + "-" + this.number.year + "-" + this.number.no;
      if (this.ty == "会议纪要") number += "-" + this.number.qi;
      return number;
    },
    numberChanged(v) {
      this.$emit("callback", v);
    },
    yearChanged(v) {
      // let documentNumber = {};
      // documentNumber.year = v;
      // documentNumber.han = this.number.han;
      // documentNumber.no = 0;
      // documentdispatchApi.getDocumentNumber(documentNumber).then((res) => {
      //   if (res.errorCode == this.$commons.RespCode.success) {
      //     let stringValue = res.data.no.toString();
      //     this.number.no =
      //       stringValue.length >= 3
      //         ? stringValue
      //         : "0".repeat(3 - stringValue.length) + stringValue;
      //   }
      // });
    },
  },
};
</script>