<template>
  <div class="tableList">
    <div>
      <a-form-model layout="inline">
        <p-button name="添加" @click="showInfo()" type="primary" />
      </a-form-model>
    </div>
    <div class="tableItem">
      <a-table
          bordered
        v-if="dictionaryInfo.type == 2"
        rowKey="id"
        :columns="tableInfo.columns"
        :data-source="tableInfo.datas"
        :pagination="false"
        :locale="{ emptyText: '暂无数据' }"
      >
        <span slot="action" slot-scope="action">
          <p-button
            name="编辑"
            @click="showInfo(action)"
            size="small"
            type="primary"
          />
          <a-divider type="vertical" />
          <p-button
            name="删除"
            @click="delInfo(action.id)"
            size="small"
            type="default"
          />
        </span>
      </a-table>

      <a-table
          bordered
        v-else
        rowKey="id"
        :columns="tableInfo.columns"
        :data-source="tableInfo.datas"
        :pagination="tableInfo.pagination"
        :loading="loading"
        @change="onChange"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: tableInfo.tableHeight }"
      >
        <span slot="status" slot-scope="status">
          <a-tag v-if="status" color="green">已启用</a-tag>
          <a-tag v-else color="red">已禁用</a-tag>
        </span>
        <span slot="action" slot-scope="action">
          <p-button
            name="编辑"
            @click="showInfo(action)"
            size="small"
            type="primary"
          />
          <a-divider type="vertical" />
          <p-button
            name="删除"
            @click="delInfo(action.id)"
            size="small"
            type="default"
          />
        </span>
      </a-table>
    </div>
    <a-modal
      :title="editor.title"
      v-model="editor.visible"
      width="50%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <dictionaryItemInfo
        :info="editor.info"
        :dinfo="dictionaryInfo"
        @callback="modelCallBack"
      />
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    dataIndex: "itemValue",
    key: "itemValue",
    align: "center",
    title: "值",
  },
  {
    dataIndex: "itemName",
    key: "itemName",
    align: "center",
    title: "名称",
  },
  {
    dataIndex: "orderno",
    key: "orderno",
    align: "center",
    title: "排序",
  },
  {
    title: "操作",
    key: "action",
    width: 200,
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

import dictionaryItemInfo from "./DictionaryItemInfo";
import dictionaryItemApi from "@/api/system/dictionaryItem";
export default {
  name: "DictionaryItemList",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      search: {
        pageNow: 1,
        pageSize: 10,
      },
      tableInfo: {
        datas: [],
        columns,
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          defaultCurrent: 1,
          defaultPageSize: 10,
          showTotal: (total) => `总共${total}条`,
        },
        // tableHeight: window.innerHeight - 300,
      },
      selectRows: [],
      editor: {
        title: "编辑",
        visible: false,
        info: {},
      },
      dictionaryInfo: {},
    };
  },
  components: {
    dictionaryItemInfo,
  },
  mounted() {
    this.dictionaryInfo = this.info;
    if (this.info.type == 2) {
      this.getTree();
    } else {
      this.getPageList();
    }
  },
  methods: {
    async getPageList() {
      let _this = this;
      let param = {
        pageNow: _this.search.pageNow,
        pageSize: _this.search.pageSize,
        dictionaryId: _this.dictionaryInfo.id,
      };
      dictionaryItemApi.getPageList(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.tableInfo.datas = res.data.records;
          _this.tableInfo.pagination.total = Number(res.data.total);
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    async getTree() {
      let _this = this;
      let param = {
        dictionaryId: _this.dictionaryInfo.id,
      };
      dictionaryItemApi.tree(param).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.tableInfo.datas = res.data;
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    onChange(page) {
      let _this = this;
      _this.search.pageNow = page.current;
      _this.search.pageSize = page.pageSize;
      _this.getPageList();
    },

    showInfo(info) {
      let _this = this;
      _this.editor.visible = true;
      if (info) {
        _this.editor.title = "编辑";
        _this.editor.info = info;
      } else {
        _this.editor.title = "新增";
        info = {
          id: null,
        };
        _this.editor.info = info;
      }
    },
    delInfo(id) {
      let _this = this;
      let param = {
        id: id,
      };
      _this.$confirm({
        title: `您确定要删除所选项吗?`,
        okText: "确定",
        okType: "primary",
        cancelText: "我再想想",
        onOk() {
          _this.loading = true;
          dictionaryItemApi
            .deleteById(param)
            .then((res) => {
              if (res.errorCode === _this.$commons.RespCode.success) {
                _this.$message.success(res.errorMsg);
                _this.getPageList();
              } else {
                _this.$message.error(res.errorMsg);
              }
            })
            .catch(() => {
              _this.loading = false;
            })
            .finally(() => {
              _this.loading = false;
            });
        },
        onCancel() {},
      });
    },

    showList(info) {
      //打开弹出框
      let _this = this;
      _this.itemListInfo.visible = true;
      _this.itemListInfo.title = "字典子集";
      _this.itemListInfo.info = info;
    },
    modelCallBack() {
      this.editor.visible = false;
      this.getPageList();
    },
  },
};
</script>

<style lang="less">
</style>