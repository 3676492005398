<template>
  <a-popover v-model="visible" placement="bottomRight" trigger="click">
    <template slot="content">
      <a-table
          bordered
        :columns="[
          {
            dataIndex: 'userName',
            title: '操作用户',
            align: 'center',
            width: 100,
          },
          {
            dataIndex: 'fileName',
            title: '文件名',
            align: 'center',
            width: 280,
          },
          {
            dataIndex: 'exportTime',
            title: '导出时间',
            align: 'center',
            width: 160,
          },
          {
            key: 'action',
            title: '操作',
            align: 'center',
            width: 100,
            scopedSlots: { customRender: 'action' },
          },
        ]"
        :data-source="fileList"
        :pagination="false"
        bordered
        size="small"
      >
        <span slot="action" slot-scope="text, record">
          <a-button
            type="link"
            @click="downloadFile(record.businessType, record.fileName)"
            >下载</a-button
          >
        </span>
      </a-table>
    </template>
    <template slot="title">
      <span>文件下载</span>
    </template>
    <a-button type="default" @click="listFiles">下载文件</a-button>
  </a-popover>
</template>

<script>
export default {
  name: "ExportFileDownloadBtn",
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    listFiles() {
      this.$emit("listFiles");
    },
    downloadFile(url, fileName) {
      this.$emit("downloadFile", url, fileName);
      this.visible = false;
    },
  },
};
</script>

<style>
</style>