<template>
  <div class="tableList">
    <div class="search">
      <div class="search-body">
        <a-form-model layout="inline">
          <a-form-model-item label="评论人">
            <a-input placeholder="请输入" v-model="search.creatorName" />
          </a-form-model-item>
          <a-form-model-item>
            <p-button type="primary" @click="searchList" name="搜索" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
    <div>
      <a-table
          bordered
        rowKey="id"
        size="small"
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ x: 1000, y: list.tableHeight }"
      >
        <span slot="action" slot-scope="text, record">
          <a-popconfirm @confirm="delInfo(record.id)">
            <div slot="title">是否确认删除此评论？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <a-button
              name="删除"
              type="danger"
              size="small"
              :roles="['评论-评论管理']"
              >刪除</a-button
            >
          </a-popconfirm>
        </span>
      </a-table>
    </div>
  </div>
</template>
  
  <script>
import commentApi from "@/api/comment";
const columns = [
  {
    key: "index",
    align: "center",
    title: "序号",
    width: 70,
    customRender: (text, record, index) => {
      return (
        (_this.list.pagination.current - 1) * _this.list.pagination.pageSize +
        index +
        1
      );
    },
  },
  {
    dataIndex: "addTime",
    align: "center",
    title: "评论时间",
    width: 180,
  },
  {
    dataIndex: "creatorName",
    align: "center",
    title: "评论人",
    width: 120,
  },
  {
    dataIndex: "comment",
    align: "center",
    title: "评论内容",
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 100,
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "CommentList",
  props: {
    guid: null,
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 330,
      },
      search: {
        guid: this.guid,
        creatorName: "",
      },
      isloading: false,
    };
  },
  mounted() {
    _this = this;
    _this.GetList();
  },
  methods: {
    GetList() {
      //获取列表
      _this.isloading = true;
      let queryData = { ..._this.search };
      commentApi
        .pageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          queryData
        )
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      this.GetList();
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      commentApi
        .deleteComment(id)
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
            _this.isloading = false;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
  },
};
</script>
  
<style>
</style>
  