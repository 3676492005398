<template>
  <a-spin tip="加载中" :spinning="loading">
    <div>
      <a-form-model
        :model="form"
        ref="websiteForm"
        :rules="rules"
        class="laiwen"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <div
          style="
            font-size: 20px;
            width: 100%;
            text-align: center;
            padding-bottom: 20px;
          "
        >
          网站信息发布
        </div>
        <table class="formtable">
          <tr>
            <td style="width: 160px">
              <span style="color: red">*</span> 撰稿部室
            </td>
            <td style="text-align: left">
              <a-form-model-item prop="orgName">
                <a-input v-model="form.orgName" disabled />
              </a-form-model-item>
            </td>
            <td style="width: 160px">
              <span style="color: red">*</span>撰稿人
            </td>
            <td style="width: 240px; text-align: left">
              <a-form-model-item prop="creatorName">
                <a-input v-model="form.creatorName" disabled />
              </a-form-model-item>
            </td>
            <td style="width: 160px">核稿</td>
            <td style="width: 240px; text-align: left">
              <auditshow
                :infoData="form"
                taskKey="网站信息发布-部室负责人核稿"
                @callback="auditCb"
              />
            </td>
          </tr>
          <tr>
            <td><span style="color: red">*</span> 标题</td>
            <td colspan="5">
              <a-form-model-item prop="title">
                <a-input v-model="form.title" />
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td><span style="color: red">*</span> 内容提要</td>
            <td colspan="5">
              <a-form-model-item prop="content">
                <a-textarea
                  v-model="form.content"
                  :autoSize="{ minRows: 5, maxRows: 8 }"
                />
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td><span style="color: red">*</span> 撰稿内容</td>
            <td colspan="5" style="text-align: left">
              <a-button
                v-if="form.status == 0 || form.status == 3"
                type="link"
                @click="showWps(1)"
                >{{ form.status == 0 ? "填写正文" : "编辑正文" }}</a-button
              >
              <a-button v-else type="link" @click="showWps(1, form.print == 1)"
                >查看正文</a-button
              >
            </td>
          </tr>
          <tr>
            <td><span style="color: red">*</span>拟发布平台</td>
            <td colspan="5" style="text-align: left">
              <a-form-model-item prop="platform">
                <a-checkbox-group
                  v-model="form.platform"
                  name="platform"
                  :options="['公开媒体', '厅委信息', '网站公布', '内网公布']"
                />
              </a-form-model-item>
            </td>
          </tr>
          <tr style="height: 100px">
            <td>办公室意见</td>
            <td>
              <div
                style="
                  float: left;
                  width: 100%;
                  min-height: 80px;
                  position: relative;
                "
              >
                <auditshow
                  :infoData="form"
                  taskKey="网站信息发布-办公室意见"
                  @callback="auditCb"
                />
              </div>
            </td>
            <td>主管院领导意见</td>
            <td colspan="3">
              <auditshow
                :infoData="form"
                taskKey="网站信息发布-分管院领导意见"
                @callback="auditCb"
              />
            </td>
          </tr>
          <tr style="height: 80px">
            <td rowspan="2">院长意见</td>
            <td rowspan="2">
              <auditshow
                :infoData="form"
                taskKey="网站信息发布-院长意见"
                @callback="auditCb"
              />
            </td>
            <td colspan="2">办公室办理结果</td>
            <td colspan="2">资源管理部办理结果</td>
          </tr>
          <tr style="height: 100px">
            <td colspan="2">
              <auditshow
                :infoData="form"
                taskKey="网站信息发布-办公室办理结果"
                @callback="auditCb"
              />
            </td>
            <td colspan="2">
              <auditshow
                :infoData="form"
                taskKey="网站信息发布-资源管理部办理结果"
                @callback="auditCb"
              />
            </td>
          </tr>
        </table>

        <a-form-model-item
          :wrapper-col="{ span: 10, offset: 10 }"
          style="margin-top: 10px"
        >
          <a-button
            v-if="form.status == 0 || form.status == 3"
            type="primary"
            @click="handleSubmit(0)"
            style="margin-right: 10px"
            >保存</a-button
          >
          <a-popconfirm
            title="提交后数据不可更改，是否确认？"
            @confirm="handleSubmit(1)"
            v-if="form.status == 0 || form.status == 3"
          >
            <a-button type="danger" style="margin-right: 10px">提交</a-button>
          </a-popconfirm>
          <a-button
            v-if="this.form.status == 2"
            @click="showWps(2)"
            style="margin-right: 10px"
            >打印</a-button
          >

          <a-button @click="cancelClick">取消</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>
  <script>
import websiteinfopublishApi from "@/api/work/websiteinfopublish";
import orgApi from "@/api/org";
import pinyin from "js-pinyin";
export default {
  name: "websiteinfopublish",
  data() {
    return {
      loading: false,
      form: {
        status: 0,
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [
          { required: true, message: "请输入内容提要", trigger: "blur" },
        ],
        platform: [
          {
            type: "array",
            required: true,
            message: "请选择拟发布平台",
            trigger: "change",
          },
        ],
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
    };
  },
  props: {
    info: {
      type: Object,
    },
  },
  mounted() {
    localStorage.removeItem("publicFileId");
    this.getOrgTree();
    if (this.info && this.info.id > 0) {
      this.getData();
    }
  },
  components: {},
  methods: {
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgs = res.data.tree;
          if (!this.info.id) {
            this.form.orgId = res.data.path[res.data.path.length - 1];
            let tree = this.orgs;
            for (var i = 0; i < res.data.path.length; ++i) {
              var id = res.data.path[i];
              var find = tree.filter((n) => n.id == id);
              if (i == res.data.path.length - 1) {
                this.form.orgName = find[0].name;
              } else {
                tree = find[0].children;
              }
            }
            this.form.creatorName = res.data.realName;
            this.form.creator = localStorage.getItem(
              this.$commons.User.userName
            );
            this.$forceUpdate();
          }
        }
      });
    },
    auditCb(data) {
      this.$emit("callback", data != null);
      this.getData();
    },
    getData() {
      this.loading = true;
      websiteinfopublishApi.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          this.form = { ...res.data, platform: res.data.platform.split("、") };
          if (
            this.info &&
            this.info.taskId &&
            this.info.taskId != this.form.taskId
          ) {
            this.form.taskId = this.info.taskId;
          }
        }
      });
    },
    handleSubmit(status) {
      this.$refs.websiteForm.validate((valid) => {
        if (valid) {
          let formData = { ...this.form, status };
          let fileId = localStorage.getItem("publicFileId");
          if (fileId != null && fileId != "") {
            formData.documentId = fileId;
          } else {
            this.$message.error("请先填写正文");
            return;
          }
          this.loading = true;
          if (this.info.id != 0) {
            formData.id = this.info.id;
          }
          formData.platform = formData.platform.join("、");
          delete formData.auditRecords;
          //添加
          websiteinfopublishApi
            .addOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == this.$commons.RespCode.success) {
                this.$message.success(res.errorMsg);
                this.$emit("callback", true);
              } else {
                this.$message.error(res.errorMsg);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      this.$emit("callback", false);
    },
    showWps(type, readOnly) {
      // type 1 正文 2 审核签
      let templateId = "websiteinfopublish";
      if (type == 2) {
        templateId = "websiteinfopublish2";
        localStorage.removeItem("publicFileId");
      }
      if (type == 1) {
        if (!this.form.title || this.form.title == "") {
          this.$message.error("请先填写标题");
          return;
        }
      }
      let innerData = Object.assign({}, this.form);
      delete innerData.auditRecords;
      delete innerData.processExecuteStatus;
      delete innerData.processId;
      delete innerData.processModelKey;
      delete innerData.processStatus;
      delete innerData.taskId;
      delete innerData.taskKey;
      delete innerData.taskRoles;
      delete innerData.taskUsers;
      delete innerData.creatorOrgId;
      if (type == 2) {
        innerData.applyTime = this.$moment(
          innerData.applyTime,
          "YYYY-MM-DD"
        ).format("YYYY年MM月DD日");

        innerData.platform = innerData.platform.join("、");

        this.form.auditRecords.forEach((a) => {
          let key =
            "taskKey" + pinyin.getFullChars(a.taskKey.split("-")[1].toString());
          if (a.taskPrintKey != null) {
            key = "taskKey" + a.taskPrintKey;
          }
          if (innerData[key] == null) {
            innerData[key] = [];
          }
          let auditPic = "";
          if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
            let s = a.auditUserSignPicture.indexOf("uas");
            auditPic =
              "http://localhost:9000/" +
              a.auditUserSignPicture.substring(
                a.auditUserSignPicture.indexOf("uas")
              );
          }
          let task = {
            auditRemark: a.auditRemark,
            auditPic: auditPic,
            auditName: a.auditUserName,
            auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
              "YYYY年MM月DD日"
            ),
          };
          innerData[key].push(task);
        });
      }
      localStorage.setItem("innerData", JSON.stringify(innerData));
      let openType = "create";
      if (type == 1) {
        let fileId = localStorage.getItem("publicFileId");
        if (fileId != null && fileId != "") {
          openType = "edit";
          templateId = fileId;
        }
        if (this.form.documentId && this.form.documentId != "") {
          localStorage.setItem("publicFileId", this.form.documentId);
          openType = "edit";
          templateId = this.form.documentId;
        }

        if (
          this.form.status == 3 &&
          this.form.contentId == localStorage.getItem("publicFileId")
        ) {
          openType = "create";
          localStorage.removeItem("publicFileId");
        }
        if (this.form.status != 0 && this.form.status != 3) {
          openType = "readOnly";
        }
      }
      const jump = this.$router.resolve({
        name: "wpsedit",
        query: {
          //要传的参数
          templateId: templateId,
          fileType: "public",
          openType: openType,
          fileName: type == 1 ? "网站信息发布" : "信息宣传审核签",
          tempUrl: "writeNumberTemp",
          printType: "websiteInfo",
        },
      });
      window.open(jump.href, "_blank");
    },
  },
};
</script>
  <style scoped>
.laiwen .ant-form-item {
  margin-bottom: 0;
}
</style>
  