<template>
  <a-spin tip="加载中" :spinning="loading">
    <div>
      <a-form-model
        :model="form"
        ref="newsCategoryForm"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="申请部室" prop="orgId">
              <a-cascader
                :options="orgs"
                v-model="form.orgId"
                @change="orgChanged"
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
                placeholder="请选择部室"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="申请日期" prop="applyTime">
              <a-date-picker v-model="form.applyTime" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="申请人" prop="applyUserName">
              <a-input-search
                class="select_search"
                readOnly
                placeholder="请选择"
                v-model="form.applyUserName"
                @click="onSearch"
                @search="onSearch"
              >
                <a-button
                  type="primary"
                  slot="enterButton"
                  icon="plus"
                  title="添加"
                />
              </a-input-search>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-table
          bordered
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :columns="columns"
          :data-source="form.items"
          :pagination="false"
          :locale="{ emptyText: '暂无数据' }"
        >
          <span slot="textbox" slot-scope="text, record, index, column">
            <a-input v-model="record[column.dataIndex]" />
          </span>
          <span slot="category" slot-scope="data">
            <a-select v-model="data.category">
              <a-select-option
                v-for="item in assetcategory"
                :value="item.itemValue"
                :key="item.itemValue"
                >{{ item.itemName }}</a-select-option
              >
            </a-select>
          </span>

          <span slot="pictures" slot-scope="text, data">
            <a-upload
              accept="image/png, image/jpeg"
              listType="picture-card"
              :action="uploadFileUrl"
              :fileList="data.pictures"
              :remove="() => upLoadRemove"
              @preview="handlePreview"
              @change="handleFileChange($event, data)"
            >
              <div v-if="data.pictures.length < picturesLength">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传照片</div>
              </div>
            </a-upload>
          </span>

          <span slot="action" slot-scope="text, row, index">
            <a-popconfirm title="确定要删除吗？" @confirm="removeItem(index)">
              <a-button
                size="small"
                type="link"
                v-if="form.status == 0 || form.status == 3"
                >删除</a-button
              >
            </a-popconfirm>
          </span>
          <template slot="footer" v-if="form.status == 0 || form.status == 3">
            <a-button @click="addItem">
              <a-icon type="plus" />
              新增</a-button
            >
          </template>
        </a-table>
        <a-form-model-item
          :wrapper-col="{ span: 10, offset: 10 }"
          style="margin-top: 10px"
        >
          <a-button
            v-if="form.status == 0 || form.status == 3"
            type="primary"
            @click="handleSubmit(0)"
            style="margin-right: 10px"
            >保存</a-button
          >
          <a-popconfirm
            title="提交后数据不可更改，是否确认？"
            @confirm="handleSubmit(1)"
            v-if="form.status == 0 || form.status == 3"
          >
            <a-button type="danger" style="margin-right: 10px">提交</a-button>
          </a-popconfirm>
          <a-button
            type="primary"
            v-if="form.processExecuteStatus && form.taskKey == '其他-审批'"
            style="margin-right: 10px"
            @click="showaudit()"
            >审批</a-button
          >
          <a-button
            type="primary"
            v-if="form.status == 2 && allowFinish"
            style="margin-right: 10px"
            @click="finishTask()"
            >办理</a-button
          >
          <a-button
            v-if="form.status == 2"
            @click="showWps"
            style="margin-right: 10px"
            >打印</a-button
          >
          <a-button @click="cancelClick">取消</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal
      title="选择用户"
      v-model="selectuservisible"
      width="70%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <selectuser @callback="selectuserCallback" />
    </a-modal>
    <a-modal
      title="审批"
      v-model="auditModal.visible"
      width="40%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="() => (previewVisible = false)"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </a-spin>
</template>
<script>
import assetrepairApi from "@/api/asset/repair";
import orgApi from "@/api/org";
import selectuser from "../components/searchuser.vue";
import dictionaryItemApi from "@/api/system/dictionaryItem";
import request from "@/request";
import pinyin from "js-pinyin";
import workflowApi from "@/api/workflow";
import workflowapi from "@/api/workflow";
const columns = [
  {
    key: "index",
    align: "center",
    title: "序号",
    width: 70,
    customRender: (text, record, index) => {
      return index + 1;
    },
  },
  {
    key: "category",
    align: "center",
    title: "资产类别",
    width: 200,
    scopedSlots: { customRender: "category" },
  },
  {
    dataIndex: "number",
    align: "center",
    title: "资产编号",
    scopedSlots: { customRender: "textbox" },
  },
  {
    dataIndex: "name",
    align: "center",
    title: "资产名称",
    scopedSlots: { customRender: "textbox" },
  },
  {
    dataIndex: "position",
    align: "center",
    title: "存放地点",
    scopedSlots: { customRender: "textbox" },
  },
  {
    dataIndex: "supplier",
    align: "center",
    title: "供应商",
    scopedSlots: { customRender: "textbox" },
  },
  {
    dataIndex: "remark",
    align: "center",
    title: "备注",
    scopedSlots: { customRender: "textbox" },
  },
  {
    key: "pictures",
    align: "center",
    title: "上传资产照片",
    width: 260,
    scopedSlots: { customRender: "pictures" },
  },

  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "assetrepair",
  data() {
    return {
      loading: false,
      form: {
        orgId: [],
        applyTime: null,
        applyUserName: "",
        status: 0,
        items: [
          {
            pictures: [],
          },
        ],
      },
      taskId: "",
      allowFinish: false,
      assetcategory: [],
      picturesLength: 4,
      upLoadRemove: true,
      columns,
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      orgs: [],
      category: [],
      way: [],
      selectuservisible: false,
      auditModal: {
        info: null,
        visible: false,
      },
      uploadFileUrl: null,
      previewVisible: false,
      previewImage: "",
      rules: {
        orgId: [
          {
            required: true,
            message: "请选择部室",
            trigger: "change",
          },
        ],
        applyTime: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        applyUserName: [
          {
            required: true,
            message: "请选择申请人",
            trigger: "change",
          },
        ],
      },
    };
  },
  props: {
    info: {
      type: Object,
    },
  },
  mounted() {
    this.getOrgTree();
    this.getDic();
    this.uploadFileUrl = request.getUploadUrl("asset");
    if (this.info && this.info.id > 0) {
      this.getData();
    }
  },
  components: {
    selectuser,
  },
  methods: {
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
    },
    async finishTask() {
      if (this.taskId == "" || this.taskId == null) {
        this.$message.error("已办理");
        return;
      }
      this.worktaskloading = true;
      var res = await workflowApi.finishTask(this.taskId);
      this.worktaskloading = false;
      if (res.errorCode === this.$commons.RespCode.success) {
        this.$message.success("操作成功");
        this.getData();
      } else {
        this.$message.error(res.errorMsg);
      }
    },
    getTask(id) {
      this.loading = true;
      this.taskId = "";
      this.allowFinish = false;
      workflowapi.getTask(id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          if (res.data != null && res.data != "" && res.data.length > 0) {
            this.taskId = res.data[0].id;
            this.allowFinish = true;
            this.$forceUpdate();
          }
        }
      });
    },
    handleFileChange({ file, fileList }, data) {
      data.pictures = fileList;
      if (file.status == "done") {
      } else if (file.status == "removed") {
        data.pictures = [];
      } else if (file.status == "error") {
        _this.$message.error("上传失败");
      }
    },
    getDic() {
      dictionaryItemApi.getItemByDictionaryName("资产类别").then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.assetcategory = res.data;
        }
      });
    },
    showaudit() {
      this.auditModal.info = this.form;
      this.auditModal.visible = true;
    },
    auditCallBack(data) {
      this.auditModal.visible = false;
      this.$emit("callback", data != null);
    },
    getData() {
      this.loading = true;
      assetrepairApi.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.orgId = res.data.orgPath;
          res.data.applyTime = res.data.applyTime
            ? this.$moment(res.data.applyTime)
            : null;
          delete res.data.orgPath;
          this.form = res.data;
          if (
            this.info &&
            this.info.taskId &&
            this.info.taskId != this.form.taskId
          ) {
            this.form.taskId = this.info.taskId;
          }
          if (this.form.items.length > 0) {
            this.form.items.forEach((e) => {
              if (e.pictures) {
                let pictures = JSON.parse(e.pictures);
                let files = [];
                for (let i = 1; i <= pictures.length; ++i) {
                  let a = pictures[i - 1];
                  files.push({
                    uid: i,
                    name: a.title,
                    status: "done",
                    response: a,
                    url: a.url,
                  });
                }
                e.pictures = files;
              }
            });
            if (this.form.status != 0 && this.form.status == 3) {
              this.upLoadRemove = false;
              this.picturesLength = 0;
            }
          }
          this.getTask(res.data.processId);
        }
      });
    },

    orgChanged(v, selectedOptions) {
      if (selectedOptions == null || selectedOptions.length == 0) {
        this.form.orgName = null;
        this.form.orgId = [];
      } else {
        this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
        this.form.orgId = v;
      }
      this.$forceUpdate();
    },
    onSearch() {
      this.selectuservisible = true;
    },
    selectuserCallback(user) {
      this.form.applyUser = user.userName;
      this.form.applyUserName = user.realName;
      this.selectuservisible = false;
    },
    addItem() {
      this.form.items.push({ pictures: [] });
    },
    removeItem(index) {
      this.form.items.splice(index, 1);
    },
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgs = res.data.tree;
          if (!this.info) {
            this.form.orgId = res.data.path;
            let tree = this.orgs;
            if (res.data.path) {
              for (var i = 0; i < res.data.path.length; ++i) {
                var id = res.data.path[i];
                var find = tree.filter((n) => n.id == id);
                if (i == res.data.path.length - 1) {
                  this.form.orgName = find[0].name;
                } else {
                  tree = find[0].children;
                }
              }
            }
            this.form.applyUserName = res.data.realName;
            this.form.applyUser = localStorage.getItem(
              this.$commons.User.userName
            );
          }
        }
      });
    },

    handleSubmit(status) {
      this.$refs.newsCategoryForm.validate((valid) => {
        if (valid) {
          let formData = { ...this.form, status };
          formData.orgId = formData.orgId[formData.orgId.length - 1];
          this.loading = true;
          if (this.info) {
            formData.id = this.info.id;
          }
          formData.applyTime = formData.applyTime.format("YYYY-MM-DD");
          delete formData.createTime;
          delete formData.creator;
          delete formData.creatorName;
          delete formData.auditRecords;
          if (this.form.items.length > 0) {
            this.form.items.forEach((e) => {
              let pictures = [];
              if (e.pictures.length > 0) {
                e.pictures.forEach((p) => {
                  pictures.push({
                    title: p.response.title,
                    url: p.response.url,
                  });
                });
              }
              e.pictures = JSON.stringify(pictures);
            });
          }
          //添加
          assetrepairApi
            .addOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == this.$commons.RespCode.success) {
                this.$message.success(res.errorMsg);
                this.$emit("callback", true);
              } else {
                this.$message.error(res.errorMsg);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      this.$emit("callback", false);
    },
    showWps() {
      let templateId = "assetrepair";

      let innerData = Object.assign({}, this.form);
      delete innerData.auditRecords;
      delete innerData.processExecuteStatus;
      delete innerData.processId;
      delete innerData.processModelKey;
      delete innerData.processStatus;
      delete innerData.taskId;
      delete innerData.taskKey;
      delete innerData.taskRoles;
      delete innerData.taskUsers;
      delete innerData.status;
      delete innerData.creatorOrgId;
      innerData.notInsertTableRow = "true";
      innerData.applyTime = this.$moment(
        innerData.applyTime,
        "YYYY-MM-DD"
      ).format("YYYY年MM月DD日");
      this.dataList = Object.assign([], innerData.items);
      if (this.dataList != null && this.dataList.length > 0) {
        let i = 0;
        this.dataList.forEach((a) => {
          a.sort = ++i;
          a.time = innerData.applyTime;
        });
        delete innerData.items;
        innerData.list = this.dataList;
      }
      this.form.auditRecords.forEach((a) => {
        let key =
          "taskKey." + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
        if (a.taskPrintKey != null) {
          key = "taskKey" + a.taskPrintKey;
        }
        if (innerData[key] == null) {
          innerData[key] = [];
        }
        let auditPic = "";
        if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
          auditPic =
            "http://localhost:9000/" +
            a.auditUserSignPicture.substring(
              a.auditUserSignPicture.indexOf("uas")
            );
        }
        let task = {
          auditRemark: a.auditRemark,
          auditPic: auditPic,
          auditName: a.auditUserName,
          auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
            "YYYY年MM月DD日"
          ),
        };
        innerData[key].push(task);
      });
      localStorage.setItem("innerData", JSON.stringify(innerData));
      localStorage.removeItem("publicFileId");
      const jump = this.$router.resolve({
        name: "wpsedit",
        query: {
          //要传的参数
          templateId: templateId,
          fileType: "public",
          openType: "create",
          fileName: "资产报修",
          tempUrl: "writeNumberTemp",
        },
      });

      window.open(jump.href, "_blank");
    },
  },
};
</script>
