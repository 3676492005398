<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <div :style="{ padding: '10px', width: '70%', margin: '0px auto' }">
        <a-table
          bordered
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :columns="columns"
          :data-source="items"
          :pagination="false"
          :locale="{ emptyText: '暂无数据' }"
        >
          <span slot="textbox" slot-scope="text, record, index, column">
            <a-input v-model="record[column.dataIndex]" />
          </span>

          <span slot="action" slot-scope="text, row, index">
            <a-button @click="removeItem(index)" size="small" type="dashed"
              >删除</a-button
            >
          </span>
          <template slot="footer">
            <div style="height: 30px">
              <a-button type="dashed" style="float: left" @click="addItem">
                <a-icon type="plus" />
                新增</a-button
              >
            </div>
          </template>
        </a-table>
        <a-row style="text-align: center; margin-top: 20px">
          <a-col>
            <a-button
              type="primary"
              style="margin-right: 5px"
              @click="handleSubmit"
              >保存</a-button
            >
          </a-col>
        </a-row>
      </div>
    </div>
  </a-spin>
</template>
<script>
import searchaccountApi from "@/api/personal/searchaccount";
const columns = [
  {
    key: "index",
    align: "center",
    title: "序号",
    width: 70,
    customRender: (text, record, index) => {
      return index + 1;
    },
  },
  {
    dataIndex: "name",
    align: "center",
    title: "系统",
    scopedSlots: { customRender: "textbox" },
  },
  {
    dataIndex: "url",
    align: "center",
    title: "访问地址",
    scopedSlots: { customRender: "textbox" },
  },
  {
    dataIndex: "userName",
    align: "center",
    title: "用户名",
    scopedSlots: { customRender: "textbox" },
  },
  {
    dataIndex: "password",
    align: "center",
    title: "密码",
    scopedSlots: { customRender: "textbox" },
  },

  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "documentsearchsetting",
  data() {
    return {
      items: [{}],
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
      loading: false,
      columns,
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    addItem() {
      this.items.push({});
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    getData() {
      this.loading = true;
      searchaccountApi.getData().then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          this.items = res.data;
          if (this.items == null) {
            this.items = [{}];
          } else {
            this.items.push({});
          }
        }
      });
    },
    handleSubmit() {
      var data = [...this.items];
      data = data.filter((d) => d.name != null);
      searchaccountApi
        .postData(data)
        .then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            this.$message.success("配置成功");
          } else {
            this.$message.error(res.errorMsg);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>