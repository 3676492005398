<template>
  <div id="components-layout-demo-basic">
    <a-layout>
      <a-layout-header style="background-color: #1d63b8">
        <div class="logo">
          <!-- <img src="~@/assets/img/logo.png" /> -->
          <span>{{ $config.shortName }}</span>
        </div>

        <div class="pxkeji-admin-header-meau" v-if="$config.headerMenu">
          <a-menu
            v-model="current"
            mode="horizontal"
            style="background-color: #1d63b8; color: white"
            class="pxkeji-horizontal-menu"
          >
            <template v-for="item in headMenuData">
              <a-menu-item
                :key="item.name"
                v-if="item.meta.menu && !item.meta.dropdown"
                @click="goTo({ item }, $event)"
              >
                <i class="iconfont" :class="item.meta.icon"></i>
                {{ item.meta.name }}
                <a-badge
                  v-if="item.meta.name == '待办事项'"
                  :count="workCount"
                  style="position: relative; top: -2px"
                />
              </a-menu-item>
              <a-sub-menu :key="item.name" v-if="item.meta.dropdown">
                <span slot="title"
                  >{{ item.meta.name }} <a-icon type="down"
                /></span>
                <a-menu-item :key="ch.name" v-for="ch in item.children">
                  <a :href="ch.meta.to" target="_blank">{{ ch.meta.name }}</a>
                </a-menu-item>
              </a-sub-menu>
            </template>
          </a-menu>
        </div>
        <div class="header_right">
          <div class="right-item" style="margin-right: 40px">
            <a-dropdown>
              <a class="ant-dropdown-link header_user_info" href="#">
                <a-avatar
                  shape="square"
                  size="default"
                  icon="user"
                  :style="{
                    verticalAlign: 'middle',
                    backgroundColor: '#1890ff',
                    marginLeft: '20px',
                  }"
                ></a-avatar>

                <span class="user_name">{{ userName }}</span>
              </a>
              <a-menu slot="overlay" class="header_select">
                <a-menu-item>
                  <a-icon type="history" />系统版本 V1.0.0.1
                </a-menu-item>
                <a-menu-item @click="modifyPass">
                  <a-icon type="lock" />设置密码
                </a-menu-item>
                <a-menu-item @click="logOut">
                  <a-icon type="logout" />退出
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <div class="right-item">
            <a-badge :count="messagecount">
              <a-button
                type="link"
                style="padding: 0; margin-left: 20px"
                @click="showmessage"
              >
                <a-icon
                  type="notification"
                  style="font-size: 20px; color: white"
                />
              </a-button>
            </a-badge>
          </div>
          <div
            class="right-item"
            style="width: 60%"
            v-if="current.indexOf('home') != -1"
          >
            <a-input-search
              enter-button
              @search="onArticleSearch"
              style="margin-top: 16px"
              placeholder="信息检索"
            />
          </div>

          <!--          <a-button-->
          <!--            type="link"-->
          <!--            style="padding: 0px 3px 0px 0px"-->
          <!--            @click="showChat"-->
          <!--          >-->
          <!--            <a-icon type="message" style="font-size: 20px; color: white" />-->
          <!--          </a-button>-->
        </div>
      </a-layout-header>

      <a-layout :style="{ marginLeft: collapsed ? '0px' : '240px' }">
        <a-layout-sider
          :style="{
            overflow: 'auto',
            height: '94vh',
            position: 'fixed',
            left: 0,
            width: collapsed ? '0px' : '240px',
            minWidth: '0px',
            backgroundColor: 'white',
          }"
          :width="240"
          :trigger="null"
          v-model="collapsed"
          collapsible
        >
          <leftMenu ref="thisLeftMenu" />
        </a-layout-sider>
        <a-layout
          style="padding: 0 16px 16px"
          class="rightMain"
          :class="homeClass"
        >
          <div style="margin: 8px 0; text-align: left"><breadcrumb /></div>
          <a-layout-content
            :style="{
              background: '#fff',
              margin: 0,
              overflow: 'initial',
            }"
          >
            <rightMain />
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
    <a-modal
      v-model="visible"
      :maskClosable="false"
      destroyOnClose
      title="修改密码"
      on-ok="handleOk"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="loading"
          @click="handleOk"
        >
          确认
        </a-button>
      </template>
      <a-form-model
        ref="resetPwdForm"
        :model="form"
        :rules="rules"
        v-bind="layout"
      >
        <a-form-model-item label="原密码" prop="passwordOld">
          <a-input
            v-model="form.passwordOld"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="passwordNew">
          <a-input
            v-model="form.passwordNew"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="checkPass">
          <a-input
            v-model="form.checkPass"
            type="password"
            autocomplete="off"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      title="短消息"
      v-model="messagemodal.visible"
      width="1100px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <message @callback="closemessagemodal" />
    </a-modal>
    <a-modal
      title=""
      v-model="chatModal.visible"
      width="1200px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      :dialog-style="{ top: '20px' }"
    >
      <Chat />
    </a-modal>
    <PageNotice ref="pageNotice" v-show="false" />
  </div>
</template>
<script>
import rightMain from "@/components/RightMain.vue";
import leftMenu from "@/components/LeftMenu.vue";
import breadcrumb from "@/components/Breadcrumb.vue";
import PageNotice from "@/components/PageNotice.vue";
import commons from "@/utils/commons.js";
import messageApi from "@/api/message";
import message from "./work/message.vue";
import workflowApi from "@/api/workflow";
import Chat from "./Chat.vue";
import { mapActions, mapGetters } from "vuex";
import systemUser from "@/api/system/systemUser";
import { intiWebsocket } from "@/assets/js/websocket.js";
export default {
  name: "Main",
  inject: ["reload"],
  data() {
    let checkPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.form.passwordNew) {
        callback(new Error("两次新密码输入不相同"));
      } else {
        callback();
      }
    };
    return {
      collapsed: false,
      userName: localStorage.getItem(commons.User.userName),
      homeClass: "homeMain",
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      },
      current: [],
      headMenuData: [],
      form: {},
      messagecount: 0,
      workCount: 0,
      rules: {
        passwordOld: [
          {
            required: true,
            message: "请输入原密码",
            trigger: "blur",
          },
        ],
        passwordNew: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
        checkPass: [
          {
            required: true,
            message: "请再次输入新密码",
            trigger: "blur",
          },
          { validator: checkPass, trigger: "change" },
        ],
      },
      visible: false,
      loading: false,
      messagemodal: {
        visible: false,
      },
      chatModal: {
        visible: false,
      },
      notificationKeys: [],
      websocketObj: null,
    };
  },
  components: {
    leftMenu,
    breadcrumb,
    rightMain,
    message,
    Chat,
    PageNotice,
  },
  created() {
    let _this = this;
    _this.websocketObj = intiWebsocket(
      _this.$config.websocketUrl + "?userName=" + _this.userName,
      (msg) => {
        _this.$refs.pageNotice.tip("您有新的待办事项", "新消息");
        let message = JSON.parse(msg);
        const key = `open${Date.now()}`;
        _this.notificationKeys.push(key);
        _this.$notification.info({
          message: (h) => {
            return h(
              "div",
              { style: "font-weight:600;font-size:18px" },
              "您有新的待办事项，请及时处理"
            );
          },
          description: (h) => {
            return [
              h(
                "div",
                { style: "font-weight:600;font-size:16px" },
                message.title
              ),
              h("div", message.remark),
            ];
          },
          placement: "bottomRight",
          duration: 0,
          btn: (h) => {
            return [
              h(
                "a-button",
                {
                  props: {
                    size: "small",
                  },
                  on: {
                    click: () => {
                      _this.notificationKeys.forEach((k) => {
                        _this.$notification.close(k);
                      });
                    },
                  },
                },
                "全部关闭"
              ),
              h(
                "a-button",
                {
                  style: {
                    marginLeft: "10px",
                  },
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      _this.$router.push({
                        path: "/work/home",
                      });
                      _this.collapsed = true;
                      _this.$notification.close(key);
                    },
                  },
                },
                "去处理"
              ),
            ];
          },
          key,
          onClose: close,
        });
      }
    );
  },
  mounted() {
    this.$nextTick(function () {
      if (this.$config.headerMenu) {
        this.headMenuData = [];
        this.headMenuData = this.getLeftMenus();
        this.hasLeftMenu =
          this.$refs.thisLeftMenu.leftMenus &&
          this.$refs.thisLeftMenu.leftMenus.filter((m) => !m.hidden).length > 0;
        if (this.$route.path == "/") {
          this.current = ["home"];
        } else {
          var parentUrl = this.$route.path.split("/");
          this.current = [parentUrl[1]];
        }
        this.collapsed = !this.hasLeftMenu;
      }
      this.getWork();
      this.getUnreadCount();
    });
  },
  computed: {
    workCountState() {
      return this.$store.state.systemMenu.workCount;
    },
    messageCountState() {
      return this.$store.state.systemMenu.messageCount;
    },
  },
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.homeClass = "homeMain";
      } else {
        this.homeClass = "";
      }
    },
    workCountState(val) {
      this.workCount = val;
    },
    messageCountState(val) {
      this.messagecount = val;
    },
  },
  methods: {
    showChat() {
      // this.chatModal.visible = true;
      // location.href='http://localhost:8080/#/chat'
      window.open(window.location.origin + "/#/chat", "_blank");
    },
    showmessage() {
      this.messagemodal.visible = true;
    },
    closemessagemodal() {
      this.messagemodal.visible = false;
    },
    async getUnreadCount() {
      var res = await messageApi.getUnreadCount();
      if (res.errorCode === this.$commons.RespCode.success) {
        this.messagecount = res.data;
        this.stateMessageCount(res.data);
      }

      var res2 = await workflowApi.getTaskForUser(1, 1);
      this.worktaskloading = false;
      if (res2.errorCode === this.$commons.RespCode.success) {
        this.workCount = Number(res2.data.total);
        this.stateWorkCount(Number(res2.data.total));
      }
    },
    async getWork() {
      var res = await workflowApi.getTaskForUser(1, 5);
      if (res.errorCode === this.$commons.RespCode.success) {
        this.workcount = Number(res.data.total);
      }
    },
    goTo(s) {
      if (s.item.meta.to) {
        window.open(
          s.item.meta.to +
            `?access_token=${this.$store.getters["currentUser/getToken"]}`
        );
        return;
      }
      var thisInfo = {
        isPass: false,
        data: s.item,
      };
      while (thisInfo.isPass == false) {
        thisInfo = this.getlastInfo(thisInfo.data);
      }
      if (s.item.children) {
        this.$router.push({ path: thisInfo.data.path });
      } else {
        this.$router.push({
          path: s.item.path,
        });
      }
      this.$refs.thisLeftMenu.LeftMenuChange(s.item, thisInfo.data);
      this.hasLeftMenu =
        this.$refs.thisLeftMenu.leftMenus &&
        this.$refs.thisLeftMenu.leftMenus.filter((m) => !m.hidden).length > 0;
      this.collapsed = !this.hasLeftMenu;
    },
    getlastInfo(e) {
      if (!e) {
        return;
      }
      if (e.children) {
        return { isPass: false, data: e.children[0] };
      } else {
        return { isPass: true, data: e };
      }
    },
    ...mapGetters({
      getLeftMenus: "systemMenu/getLeftMenus",
      getAuthoritys: "currentUser/getAuthoritys",
    }),
    ...mapActions({
      stateLogout: "currentUser/logout",
      stateWorkCount: "systemMenu/setWorkCount",
      stateMessageCount: "systemMenu/setMessageCount",
    }),
    async logOut() {
      let _this = this;
      _this.notificationKeys.forEach((k) => {
        _this.$notification.close(k);
      });
      await _this.stateLogout();
      _this.websocketObj.close();
      _this.$router.push({ path: "/login" });
    },
    modifyPass() {
      let _this = this;
      _this.visible = true;
    },
    handleOk() {
      let _this = this;
      _this.$refs.resetPwdForm.validate((valid) => {
        if (valid) {
          _this.loading = true;
          systemUser.updatePassword(_this.form).then((res) => {
            if (res.errorCode === _this.$commons.RespCode.success) {
              _this.$message.success("修改成功");
              _this.visible = false;
            } else {
              _this.$message.error(res.errorMsg);
            }
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      let _this = this;
      _this.visible = false;
    },
    onArticleSearch(value) {
      if (value == "") {
        this.$message.warning("请输入检索内容");
        return;
      }
      if (this.$route.path != "/home/newslist") {
        this.$router.push({
          name: "newslist",
          query: { title: value },
        });
      } else {
        this.$route.query.title = value;
        this.reload();
      }
    },
  },
};
</script>
<style lang="less">
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-selected {
  color: white;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: white;
  background-color: #20528f;
  border-bottom-color: #20528f;
}
#components-layout-demo-basic {
  .ant-layout-header {
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    background: #fff;
    padding: 0;
  }
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    float: left;
    color: white;
  }
  .trigger:hover {
    color: #1890ff;
  }
  .logo {
    float: left;
    height: 64px;
    width: 200px;
    color: #fff;
    text-align: center;
    img {
      height: 100%;
      vertical-align: center;
    }
    span {
      display: inline-block;
      height: 64px;
      line-height: 64px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .rightMain {
    min-height: calc(100vh - 64px);
    overflow: auto;
  }
  .header_right {
    float: right;
    width: 30%;
    .right-item {
      float: right;
    }
  }
  .user_name {
    margin-left: 16px;
    color: white;
  }
  .header_select a i {
    margin-right: 8px;
  }
  .pxkeji-admin-header-meau {
    float: left;
    width: 50%;
  }
  .pxkeji-admin-header-meau .ant-menu-horizontal {
    line-height: 62px;
  }
  .pxkeji-admin-header-meau .ant-menu-item {
    font-size: 16px;
    font-weight: 600;
  }
  .pxkeji-admin-header-meau .ant-menu-submenu-title {
    font-size: 16px;
    font-weight: 600;
  }
  .pxkeji-admin-header-meau .ant-menu-item .iconfont {
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
