import request from "@/request/index";
const part_url = "/oa/websiteinfopublish/";

export default {
    addOrUpdate(params) {
        let url = part_url + "addOrUpdate";
        return request.post(url, {}, params);
    },
    getList(params) {
        let url = part_url + "getList";
        return request.get(url, params, {});
    },
    changeStatus(id, status) {
        let url = part_url + "changeStatus";
        return request.get(url, { id, status }, {});
    },
    getData(id) {
        let url = part_url + "getData";
        return request.get(url, { id }, {});
    },
    deleteItem(id) {
        let url = part_url + "deleteItem";
        return request.get(url, { id }, {});
    },
}
