<template>
  <a-spin :tip="loadingTip" :spinning="loading">
    <a-form-model
      ref="auditForm"
      :model="audit"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item v-if="cando" :wrapper-col="{ span: 14, offset: 8 }">
        <p-button
          style="margin-left: 10px"
          v-if="fenpai"
          type="danger"
          @click="buildsub"
          name="分派"
        />
        <template v-else>
          <a-button
            type="primary"
            @click="onSubmit(1)"
            name="确定"
            :disabled="isDisabled"
            >确定</a-button
          >
        </template>
        <p-button style="margin-left: 10px" name="取消" @click="cancelSubmit" />
      </a-form-model-item>
    </a-form-model>
  </a-spin>
</template>

<script>
import workflowApi from "@/api/workflow";
import orgApi from "@/api/org";
import selectuser from "../components/searchuser.vue";
export default {
  name: "auditone",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    getPostDataFunc: {
      type: Function,
    },
    formValidPass: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      isDisabled: false,
      fenpai: false,
      loadingTip: "加载中...",
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      selectuservisible: false,
      selectuserto: "nextUser",
      audit: {
        auditRemark: "",
      },
      orgDates: [],
      cando: false,
      definitionData: {},
      users: [],
      manualNext: false,
      rules: {
        auditRemark: [
          {
            required: true,
            message: "请输入意见",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    selectuser,
  },
  mounted() {
    if (this.info) {
      this.getOrgTree();
      this.getDefinition();
    }
  },
  methods: {
    getOrgTree() {
      orgApi.getTree(true, false).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgDates = res.data[0].children;
        }
      });
    },
    onSearch() {
      this.selectuservisible = true;
    },
    selectuserCallback(user) {
      if (this.selectuserto == "nextUser") {
        this.audit.nextUser = "";
        this.audit.nextUserName = "";
        if (user) {
          this.audit.nextUser = user.map((u) => u.userName).join(",");
          this.audit.nextUserName = user.map((u) => u.realName).join(",");
          this.audit.nextCount = user.length;
        }
      } else {
        this.audit.transferToUser = "";
        this.audit.transferToUserName = "";
        if (user) {
          this.audit.transferToUser = user.map((u) => u.userName).join(",");
          this.audit.transferToUserName = user.map((u) => u.realName).join(",");
        }
      }
      this.selectuservisible = false;
    },
    nodeChanged(v) {
      if (v == null || v == "") {
        return;
      }
      this.audit.nextUser = "";
      var current = this.definitionData.filter((n) => n.id == v);
      if (current.length > 0 && current[0].targetUsers) {
        let _users = current[0].targetUsers.split(",");
        this.users = [];
        _users.forEach((item) => {
          this.users.push({
            userName: item.split(":")[0],
            realName: item.split(":")[1],
          });
        });

        // this.users=current[0].targetUsers.split(",");
        this.$forceUpdate();
      } else {
        this.users = [];
      }
    },
    async getDefinition() {
      this.loading = true;
      var auditRecords = [];
      await workflowApi
        .getAuditRecord(this.info.processModelKey, this.info.id)
        .then((res) => {
          if (res.errorCode == "0000") {
            auditRecords = res.data;
          }
        });
      await workflowApi.getDefinitionForTask(this.info.taskId).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          let aa = JSON.parse(res.data.definition).tasks;
          var current = aa.filter((n) => n.id == this.info.currentNode);
          if (current.length == 0) {
            return;
          }
          var cNode = current[0];
          if (cNode.taskPrintKey) {
            this.audit.taskPrintKey = cNode.taskPrintKey;
          }
          if (cNode.manualNext) {
            this.manualNext = true;
          }
          this.audit = {
            taskId: this.info.taskId,
            taskKey: this.info.taskKey,
            businessId: this.info.id,
            businessType: this.info.processModelKey,
            taskPrintKey: cNode.taskPrintKey,
            nodeId: this.info.currentNode,
            nextUser: "",
            nextNodeId: "",
            updateModel: cNode.auditIncludeForm
              ? cNode.auditIncludeForm
              : false,
            auditRemark: "",
            time: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
            auditUserName: cNode.targetUsers
              ? cNode.targetUsers.split(":")[1]
              : "",
            finishDirectly: this.info.finishDirectly || 0,
          };
          this.cando = true;

          for (let j = 0; j < auditRecords.length; j++) {
            for (let i = 0; i < aa.length; i++) {
              if (
                aa[i].name == auditRecords[j].taskKey ||
                aa[i].taskKey == auditRecords[j].taskKey
              ) {
                aa.splice(i, 1);
                i--;
              }
            }
          }
          for (let i = 0; i < aa.length; i++) {
            if (aa[i].id == this.info.currentNode) {
              aa.splice(i, 1);
              i--;
            }
          }
          this.definitionData = aa;
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    buildsub() {
      let _this = this;
      _this.$refs.auditForm.validate((valid) => {
        if (valid) {
          var data = {
            ...this.audit,
            time: this.audit.time,
          };
          workflowApi.buildSub(data).then((res) => {
            if (res.errorCode === _this.$commons.RespCode.success) {
              _this.$message.success("操作成功");
              _this.$emit("callback", data);
            } else {
              _this.$message.error(res.errorMsg);
            }
          });
        } else {
          this.$message.error("数据不完整");
          return false;
        }
      });
    },
    onSubmit(result) {
      this.isDisabled = true;
      let _this = this;
      const moment = require("moment");
      this.audit.time = moment().format("YYYY-MM-DD HH:mm:ss");
      var data = {
        ...this.audit,
        time: this.audit.time,
      };
      data.auditResult = result;
      if (data.nextOrgs) {
        data.nextOrgs = data.nextOrgs.map((o) => o + "/").join("|");
      }
      workflowApi.postAuditData(data).then((res) => {
        if (res.errorCode === _this.$commons.RespCode.success) {
          _this.$message.success("操作成功");
          _this.$emit("callback", data);
          if (
            this.info.processModelKey == "documentdispatch" &&
            (this.info.type == "请示" ||
              this.info.type == "报告" ||
              this.info.type == "党支部请示")
          )
            workflowApi.writeAuditRecord(data.businessId).then((ress) => {
              this.isDisabled = false;
            });
        } else {
          _this.$message.error(res.errorMsg);
          this.isDisabled = false;
        }
      });
    },
    cancelSubmit() {
      this.$emit("callback");
    },
  },
};
</script>
