<template>
  <a-spin :spinning="loading" loadingTip="加载中">
    <div>
      <a-row>
        <a-col :span="4">
          <a-form-model
            ref="dictionaryForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item label="名称" prop="name">
              <a-input v-model="form.name" placeholder="请输入名称" />
            </a-form-model-item>
            <a-form-model-item label="描述" prop="description">
              <a-textarea v-model="form.description" placeholder="请输入描述" />
            </a-form-model-item>
            <a-form-model-item
              label="模型"
              prop="processModelKey"
              props="processModelKey"
            >
              <a-select
                v-model="form.processModelKey"
                placeholder="请选择"
                allowClear
              >
                <a-select-option
                  v-for="(item, key) in processModels"
                  :value="key"
                  :key="key"
                  :title="item"
                  >{{ item }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              :wrapper-col="{ offset: 4 }"
              v-if="info.action != 'show'"
            >
              <p-button type="primary" @click="postData" name="保存" />
              <p-button
                style="margin-left: 10px"
                name="取消"
                @click="cancelSubmit"
              />
            </a-form-model-item>
          </a-form-model>
        </a-col>

        <a-col :span="20">
          <div id="container">
            <div
              id="stencil"
              style="float: left; height: calc(100vh - 110px)"
            ></div>
            <div id="graph-container"></div>
          </div>
        </a-col>
      </a-row>
      <a-modal
        title="参数配置"
        :visible="cellSettingVisible"
        width="50%"
        :footer="false"
        @cancel="saveSetting"
        destroyOnClose
      >
        <a-form
          v-if="editCellType == 'task'"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-form-item label="任务名称">
            <a-input v-model="cellSetting.name" />
          </a-form-item>

          <a-form-item label="执行人">
            <a-input-search
              class="select_search"
              readOnly
              placeholder="请选择"
              v-model="cellSetting.targetUserNames"
              @click="onSearch"
              @search="onSearch"
              allowClear
            >
              <a-button
                type="primary"
                slot="enterButton"
                icon="plus"
                title="添加"
              />
            </a-input-search>
            <a-button size="small" @click="setTargetUsers('$发布者', '发布者')"
              >发布者</a-button
            >
          </a-form-item>
          <a-form-item label="执行角色">
            <a-input v-model="cellSetting.targetRoles" />
          </a-form-item>
          <a-form-item label="组织机构">
            <a-input v-model="cellSetting.targetOrg" />
            <a-button
              size="small"
              style="margin-right: 5px"
              @click="setTargetOrg('./{\'mainNode\':true}')"
              >部室负责人</a-button
            >
            <a-button
              size="small"
              style="margin-right: 5px"
              @click="setTargetOrg('/副院长?{\'leadOrgs\':\'$currentOrg\'}')"
              >分管院领导</a-button
            >
            <a-button
              size="small"
              style="margin-right: 5px"
              type="danger"
              @click="setTargetOrg('/院长、党总支书记')"
              >院长、党总支书记</a-button
            >
            <a-button
              size="small"
              style="margin-right: 5px"
              @click="setTargetOrg('/财务{\'mainNode\':true}')"
              >财务主管</a-button
            >
            <a-button
              size="small"
              type="danger"
              @click="setTargetOrg('/院长办公会')"
              >院长办公会</a-button
            >
          </a-form-item>
          <a-form-item label="执行次数">
            <a-input v-model="cellSetting.count" />
          </a-form-item>
          <a-form-item label="每人可执行多次">
            <a-switch v-model="cellSetting.multiple" />
          </a-form-item>
          <a-form-item label="可手工流转">
            <a-switch v-model="cellSetting.manualNext" />
          </a-form-item>
          <a-form-item label="可编辑业务数据">
            <a-switch v-model="cellSetting.auditIncludeForm" />
          </a-form-item>
          <a-form-item label="等待执行结果">
            <a-switch v-model="cellSetting.waitResult" />
          </a-form-item>
          <a-form-item label="打印索引">
            <a-input v-model="cellSetting.taskPrintKey" />
          </a-form-item>
          <a-form-item label="表单操作">
            <a-select
              v-model="cellSetting.taskKey"
              placeholder="请选择操作"
              allowClear
            >
              <a-select-opt-group v-for="item in forms" :key="item.name">
                <span slot="label">{{ item.name }}</span>
                <a-select-option
                  :value="item.name + '-' + action"
                  v-for="action in item.actions"
                  :key="item.name + '-' + action"
                >
                  {{ action }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
            <a-button type="primary" @click="saveSetting"> 确定 </a-button>
          </a-form-item>
        </a-form>
        <a-form
          v-else-if="editCellType == 'edge'"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-form-item label="条件">
            <!--              <a-input-->
            <!--                v-model="cellSetting.expression"-->
            <!--                />-->
            <a-textarea
              v-model="cellSetting.expression"
              placeholder="Controlled autosize"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-item>

          <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
            <a-button type="primary" @click="saveSetting"> 确定 </a-button>
          </a-form-item>
        </a-form>
        <a-form
          v-else-if="editCellType == 'service'"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >
          <a-form-item label="节点名称">
            <a-input v-model="cellSetting.name" />
          </a-form-item>

          <a-form-item label="服务名称">
            <a-input v-model="cellSetting.service" />
            <a-button
              type="primary"
              size="small"
              @click="setService('share', 'message/send')"
              >发送站内信</a-button
            >
            <a-button
              type="primary"
              size="small"
              @click="setService('oa', 'workflow/sendCopy')"
              >抄送</a-button
            >
          </a-form-item>
          <a-form-item label="方法名称">
            <a-input v-model="cellSetting.method" />
          </a-form-item>
          <a-form-item label="参数">
            <a-textarea v-model="cellSetting.parameters" />
          </a-form-item>
          <a-form-item label="执行角色">
            <a-input v-model="cellSetting.targetRoles" />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
            <a-button type="primary" @click="saveSetting"> 确定 </a-button>
          </a-form-item>
        </a-form>
      </a-modal>

      <a-modal
        title="选择用户"
        v-model="selectuservisible"
        width="70%"
        :footer="false"
        destroyOnClose
        :maskClosable="false"
      >
        <selectuser
          :multi="true"
          @callback="selectuserCallback"
          :check-users="cellSetting.targetUserNames"
        />
      </a-modal>
    </div>
  </a-spin>
</template>
<script>
import { Graph, Shape } from "@antv/x6";
import { Snapline } from "@antv/x6-plugin-snapline";
import { Transform } from "@antv/x6-plugin-transform";
import { Stencil } from "@antv/x6-plugin-stencil";
import selectuser from "@/views/business/components/searchuser.vue";
import workflowApi from "@/api/workflow/index";
export default {
  name: "workflowdefinition",
  components: { selectuser },
  data() {
    return {
      data: {
        start: {},
        finish: {},
        tasks: [],
        services: [],
        flows: [],
      },
      selectuservisible: false,
      form: {},
      editCell: {},
      editCellType: "",
      cellSetting: {},
      cellSettingVisible: false,
      loadingTip: "加载中...",
      loading: false,
      labelCol: { span: 7 },
      wrapperCol: { span: 16 },
      forms: [
        {
          name: "来文阅办",
          actions: [
            "领导批示",
            "办公室副主任意见",
            "办公室主任意见",
            "分管批阅承办意见",
            "部门批阅承办意见",
            "办公室副主任协调承办",
            "办公室副主任协调办理",
            "来文办理",
          ],
        },
        {
          name: "省教科院发文卡",
          actions: [
            "核稿",
            "会签意见",
            "部室意见",
            "职能部室",
            "办公室意见",
            "院长签发",
            "主管领导意见",
          ],
        },
        {
          name: "省规划办发文卡",
          actions: [
            "核稿",
            "会签意见",
            "规划办秘书处意见",
            "科研管理部意见",
            "主任签发",
            "副主任意见",
          ],
        },
        {
          name: "省教育学会发文卡",
          actions: [
            "核稿",
            "会签意见",
            "秘书处意见",
            "学术团体部意见",
            "秘书处签发",
            "常务副秘书长意见",
          ],
        },
        {
          name: "公用经费预算",
          actions: [
            "编报部门负责人意见",
            "编报部门分管领导意见",
            "办公室副主任意见",
            "办公室主任意见",
            "主管财务领导意见",
            "单位负责人意见",
          ],
        },
        {
          name: "专项资金预算",
          actions: [
            "项目负责人意见",
            "项目管理部门负责人意见",
            "财务意见",
            "办公室财务副主任意见",
            "办公室主人意见",
            "项目管理部门分管院领导意见",
            "分管财务院领导意见",
            "院长意见",
          ],
        },
        {
          name: "专项资金支出申请",
          actions: [
            "项目负责人意见",
            "项目管理部门负责人意见",
            "财务意见",
            "办公室财务副主任意见",
            "办公室主人意见",
            "项目管理部门分管院领导意见",
            "分管财务院领导意见",
            "院长意见",
          ],
        },
        {
          name: "代管资金预算",
          actions: [
            "项目负责人意见",
            "项目管理部门负责人意见",
            "财务意见",
            "办公室财务副主任意见",
            "办公室主人意见",
            "项目管理部门分管院领导意见",
            "分管财务院领导意见",
            "院长意见",
          ],
        },
        {
          name: "公用票据整理单",
          actions: [
            "财务",
            "办公室副主任",
            "办公室主任",
            "分管办公室院领导",
            "院长批示",
          ],
        },
        {
          name: "收入票据整理单",
          actions: [
            "财务",
            "办公室副主任",
            "办公室主任",
            "分管办公室院领导",
            "院长批示",
          ],
        },
        {
          name: "公务租车申请",
          actions: ["办公室主任意见", "主管办公室的院领导意见", "院长意见"],
        },
        {
          name: "差旅费报销",
          actions: ["其他报销人会签"],
        },
        {
          name: "文章",
          actions: ["发布"],
        },
        {
          name: "邮寄",
          actions: ["部室主任(签字)"],
        },
        {
          name: "文印",
          actions: ["部室主任(签字)"],
        },
        {
          name: "网站信息发布",
          actions: [
            "部室负责人核稿",
            "办公室意见",
            "分管院领导意见",
            "院长意见",
            "办公室办理结果",
            "资源管理部办理结果",
          ],
        },
        {
          name: "其他",
          actions: ["审批", "填写文号", "验收"],
        },
      ],
      processModels: [],
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        processModelKey: [
          {
            required: true,
            message: "请选择业务模型",
            trigger: "blur",
          },
        ],
      },
      graph: null,
      stencil: null,
    };
  },
  props: {
    info: Object,
  },
  mounted() {
    this.initGraph();
    this.initTool();
    this.getProcessModelMeta();
    if (this.info) {
      this.form = {
        id: this.info.action == "copy" ? null : this.info.id,
        name: this.info.name,
        description: this.info.description,
        processModelKey:
          this.info.action == "copy" ? null : this.info.processModelKey,
      };
      if (this.info.toolData) {
        this.data = JSON.parse(this.info.toolData);
        this.graph.fromJSON(this.data); // 居中显示
        this.graph.centerContent(); // 将画布中元素居中展示
      }
    }
  },
  beforeDestroy() {
    if (this.graph) {
      this.graph.dispose();
    }
  },
  methods: {
    onSearch() {
      this.selectuservisible = true;
    },
    selectuserCallback(user) {
      this.cellSetting.targetUsers = "";
      this.cellSetting.targetUserNames = "";
      if (user) {
        this.cellSetting.targetUsers = user.map((u) => u.userName).join(",");
        this.cellSetting.targetUserNames = user
          .map((u) => u.realName)
          .join(",");
      }
      this.selectuservisible = false;
    },
    setService(s, m) {
      this.cellSetting.service = s;
      this.cellSetting.method = m;
      this.$forceUpdate();
    },
    setTargetOrg(org) {
      this.cellSetting.targetOrg = org;
      this.$forceUpdate();
    },
    setTargetUsers(u, n) {
      this.cellSetting.targetUserNames = n;
      this.cellSetting.targetUsers = u;
      this.$forceUpdate();
    },
    saveSetting() {
      if (
        (this.cellSetting.taskKey == "其他-审批" ||
          this.cellSetting.taskKey == "其他-填写文号" ||
          this.cellSetting.taskKey == "其他-验收" ||
          this.cellSetting.taskKey == "") &&
        (this.cellSetting.taskPrintKey == "" ||
          this.cellSetting.taskPrintKey == null)
      ) {
        this.$message.info("请输入打印索引值，可能会影响审批过程打印");
      }

      if (
        this.cellSetting.targetUserNames != null &&
        this.cellSetting.targetUserNames != ""
      ) {
        if (
          this.cellSetting.targetUsers == null ||
          this.cellSetting.targetUsers == ""
        ) {
          this.$message.info("执行人需通过选择配置");
          return;
        }
      }

      this.cellSettingVisible = false;
      this.editCell.data = this.cellSetting;
      if (this.editCell.isNode()) {
        this.editCell.attr(
          "label/text",
          this.editCell.isNode()
            ? this.cellSetting.name
            : this.cellSetting.expression
        );
      } else {
        this.editCell.setLabels([this.cellSetting.expression]);
      }
    },

    showSetting(cell) {
      if (cell.isNode()) {
        if (!cell.data) {
          cell.data = {
            name: cell.attrs.text.text,
          };
        }
      }

      this.editCell = cell;

      this.cellSetting = cell.data ? cell.data : {};
      if (this.cellSetting.waitResult == null) {
        this.cellSetting.waitResult = true;
      }
      if (this.cellSetting.multiple == null) {
        this.cellSetting.multiple = false;
      }
      if (this.cellSetting.manualNext == null) {
        this.cellSetting.manualNext = false;
      }
      if (this.cellSetting.auditIncludeForm == null) {
        this.cellSetting.auditIncludeForm = false;
      }
      if (this.cellSetting.targetUserNames == null) {
        this.cellSetting.targetUserNames = "";
        this.cellSetting.targetUsers = "";
      }

      this.editCellType = cell.isNode() ? cell.shape : "edge";
      this.cellSettingVisible = true;
    },
    getProcessModelMeta() {
      workflowApi.getProcessModelMeta().then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          this.processModels = res.data.modelMetas;
          this.taskMetas = res.data.taskMetas;
        }
      });
    },
    cancelSubmit() {
      this.$emit("callback");
    },
    buildDefinition(json) {
      var cells = json.cells;
      var result = {
        start: {},
        finish: {},
        tasks: [],
        services: [],
      };
      var flows = [];
      var tasks = [];
      var services = [];
      cells.forEach(function (item) {
        if (item.shape == "edge") {
          flows.push({
            ...item.data,
            id: item.id,
            from: item.source.cell,
            target: item.target.cell,
          });
        } else if (item.shape == "start") {
          result.start = {
            id: item.id,
            name: item.attrs.text.text,
          };
        } else if (item.shape == "finish") {
          result.finish = {
            id: item.id,
            name: item.attrs.text.text,
          };
        } else if (item.shape == "task") {
          if (item.data) {
            tasks.push({
              ...item.data,
              id: item.id,
            });
          } else {
            tasks.push({
              id: item.id,
              name: item.attrs.text.text,
            });
          }
        } else if (item.shape == "service") {
          if (item.data) {
            services.push({
              ...item.data,
              id: item.id,
            });
          } else {
            services.push({
              id: item.id,
              name: item.attrs.text.text,
            });
          }
        }
      });

      tasks.forEach((task) => {
        task.flows = [];
        flows
          .filter((f) => f.from == task.id)
          .forEach((flow) => {
            task.flows.push(flow);
          });
      });
      services.forEach((task) => {
        task.flows = [];
        flows
          .filter((f) => f.from == task.id)
          .forEach((flow) => {
            task.flows.push(flow);
          });
      });
      result.start.flows = [];
      if (result.start.id) {
        flows
          .filter((f) => f.from == result.start.id)
          .forEach((flow) => {
            result.start.flows.push(flow);
          });
      }

      result.tasks = tasks;
      result.services = services;
      return result;
    },
    link(data, flows) {
      if (flows == null || flows.length == 0) {
        return false;
      }
      for (var i = 0; i < flows.length; ++i) {
        var id = flows[i].target;
        if (data.finish.id == id) {
          return true;
        }
        var ts = data.tasks.filter((t) => t.id == id);
        if (ts.length > 0) {
          if (!this.link(data, ts[0].flows)) {
            return false;
          }
        }
        ts = data.services.filter((t) => t.id == id);
        if (ts.length > 0) {
          if (!this.link(data, ts[0].flows)) {
            return false;
          }
        }
      }
      return true;
    },
    checkRoute(data) {
      if (!data.start.id || !data.finish.id) {
        this.$message.error("流程必须存在开始结束节点");
        return false;
      }
      if (!this.link(data, data.start.flows)) {
        this.$message.error("存在无法到达结束节点的路径");
        return false;
      }
      return true;
    },
    postData() {
      var r = this.buildDefinition(this.graph.toJSON());
      if (!this.checkRoute(r)) {
        return;
      }
      var data = { ...this.form };
      data.type = "json";
      data.definition = JSON.stringify(r);
      data.toolData = JSON.stringify(this.graph.toJSON().cells);
      this.loading = true;
      workflowApi.saveOrUpdate(data).then((res) => {
        this.loading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success("操作成功");
          this.$emit("callback");
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    initTool() {
      this.stencil = new Stencil({
        title: "流程图",
        target: this.graph,
        stencilGraphWidth: 200,
        stencilGraphHeight: 180,
        collapsable: false,
        groups: [
          {
            title: "基础流程图",
            name: "group1",
          },
        ],
        layoutOptions: {
          columns: 2,
          columnWidth: 80,
          rowHeight: 55,
        },
      });
      document.getElementById("stencil").appendChild(this.stencil.container);
      const ports = {
        groups: {
          top: {
            position: "top",
            attrs: {
              circle: {
                r: 4,
                magnet: true,
                stroke: "#5F95FF",
                strokeWidth: 1,
                fill: "#fff",
                style: {
                  visibility: "hidden",
                },
              },
            },
          },
          right: {
            position: "right",
            attrs: {
              circle: {
                r: 4,
                magnet: true,
                stroke: "#5F95FF",
                strokeWidth: 1,
                fill: "#fff",
                style: {
                  visibility: "hidden",
                },
              },
            },
          },
          bottom: {
            position: "bottom",
            attrs: {
              circle: {
                r: 4,
                magnet: true,
                stroke: "#5F95FF",
                strokeWidth: 1,
                fill: "#fff",
                style: {
                  visibility: "hidden",
                },
              },
            },
          },
          left: {
            position: "left",
            attrs: {
              circle: {
                r: 4,
                magnet: true,
                stroke: "#5F95FF",
                strokeWidth: 1,
                fill: "#fff",
                style: {
                  visibility: "hidden",
                },
              },
            },
          },
        },
        items: [
          {
            group: "top",
          },
          {
            group: "right",
          },
          {
            group: "bottom",
          },
          {
            group: "left",
          },
        ],
      };
      Graph.registerNode(
        "task",
        {
          inherit: "rect",
          width: 66,
          height: 36,
          attrs: {
            body: {
              strokeWidth: 1,
              stroke: "#5F95FF",
              fill: "#EFF4FF",
            },
            text: {
              fontSize: 12,
              fill: "#262626",
            },
          },
          ports: { ...ports },
        },
        true
      );
      Graph.registerNode(
        "service",
        {
          inherit: "rect",
          width: 66,
          height: 36,
          attrs: {
            body: {
              strokeWidth: 1,
              stroke: "#5F95FF",
              fill: "#EFF4FF",
              rx: 6,
              ry: 6,
            },
            text: {
              fontSize: 12,
              fill: "#262626",
            },
          },
          ports: { ...ports },
        },
        true
      );
      Graph.registerNode(
        "start",
        {
          inherit: "rect",
          width: 66,
          height: 36,
          attrs: {
            body: {
              strokeWidth: 1,
              stroke: "#5F95FF",
              fill: "#EFF4FF",
              rx: 20,
              ry: 26,
            },
            text: {
              fontSize: 12,
              fill: "#262626",
            },
          },
          ports: { ...ports },
        },
        true
      );
      Graph.registerNode(
        "finish",
        {
          inherit: "circle",
          width: 45,
          height: 45,
          attrs: {
            body: {
              strokeWidth: 1,
              stroke: "#5F95FF",
              fill: "#EFF4FF",
            },
            text: {
              fontSize: 12,
              fill: "#262626",
            },
          },
          ports: { ...ports },
        },
        true
      );

      const r1 = this.graph.createNode({
        shape: "start",
        label: "开始",
      });
      const r2 = this.graph.createNode({
        shape: "task",
        label: "任务",
      });
      const r4 = this.graph.createNode({
        shape: "service",
        label: "服务",
      });
      const r3 = this.graph.createNode({
        shape: "finish",
        label: "结束",
      });
      this.stencil.load([r1, r2, r3, r4], "group1");
    },
    initGraph() {
      let height = window.innerHeight - 110;
      this.graph = new Graph({
        container: document.getElementById("graph-container"),
        height: height,
        grid: true,
        mousewheel: {
          enabled: true,
          zoomAtMousePosition: true,
          modifiers: "ctrl",
          minScale: 0.5,
          maxScale: 3,
        },
        connecting: {
          router: "manhattan",
          connector: {
            name: "rounded",
            args: {
              radius: 8,
            },
          },
          anchor: "center",
          connectionPoint: "anchor",
          allowBlank: false,
          snap: {
            radius: 20,
          },
          createEdge() {
            return new Shape.Edge({
              attrs: {
                line: {
                  stroke: "#A2B1C3",
                  strokeWidth: 2,
                  targetMarker: {
                    name: "block",
                    width: 12,
                    height: 8,
                  },
                },
              },
              zIndex: 0,
            });
          },
          validateConnection({ targetMagnet }) {
            return !!targetMagnet;
          },
        },
        highlighting: {
          magnetAdsorbed: {
            name: "stroke",
            args: {
              attrs: {
                fill: "#5F95FF",
                stroke: "#5F95FF",
              },
            },
          },
        },
      });
      this.graph
        .use(
          new Snapline({
            enabled: true,
          })
        )
        .use(
          new Transform({
            resizing: true,
            rotating: true,
          })
        );

      const showPorts = (ports, show) => {
        for (let i = 0, len = ports.length; i < len; i += 1) {
          ports[i].style.visibility = show ? "visible" : "hidden";
        }
      };
      this.graph.on("node:mouseenter", ({ node }) => {
        const container = document.getElementById("graph-container");
        const ports = container.querySelectorAll(".x6-port-body");
        showPorts(ports, true);

        node.addTools({
          name: "button-remove",
          args: {
            x: 0,
            y: 0,
            offset: { x: 10, y: 10 },
          },
        });
      });
      this.graph.on("node:mouseleave", ({ node }) => {
        const container = document.getElementById("graph-container");
        const ports = container.querySelectorAll(".x6-port-body");
        showPorts(ports, false);
        node.removeTools();
      });
      this.graph.on("edge:mouseenter", ({ cell }) => {
        cell.addTools([
          {
            name: "source-arrowhead",
          },
          {
            name: "target-arrowhead",
            args: {
              attrs: {
                fill: "red",
              },
            },
          },
        ]);
        cell.addTools({
          name: "button-remove",
          args: { distance: -40 },
        });
      });

      this.graph.on("edge:mouseleave", ({ cell }) => {
        cell.removeTools();
      });
      this.graph.on("cell:dblclick", (e) => {
        this.showSetting(e.node ? e.node : e.edge);
      });
    },
  },
};
</script>
<style>
#container {
  display: flex;
  border: 1px solid #dfe3e8;
  width: 100%;
}

#stencil {
  width: 180px;
  height: 100%;
  position: relative;
  border-right: 1px solid #dfe3e8;
}

#graph-container {
  width: calc(100% - 150px);
  height: calc(100% - 110px);
}

.x6-widget-stencil {
  background-color: #fff;
}

.x6-widget-stencil-title {
  background-color: #fff;
}

.x6-widget-stencil-group-title {
  background-color: #fff !important;
}

.x6-widget-transform {
  margin: -1px 0 0 -1px;
  padding: 0px;
  border: 1px solid #239edd;
}

.x6-widget-transform > div {
  border: 1px solid #239edd;
}

.x6-widget-transform > div:hover {
  background-color: #3dafe4;
}

.x6-widget-transform-active-handle {
  background-color: #3dafe4;
}

.x6-widget-transform-resize {
  border-radius: 0;
}

.x6-widget-selection-inner {
  border: 1px solid #239edd;
}

.x6-widget-selection-box {
  opacity: 0;
}
</style>
